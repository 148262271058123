<template>
  <BaseCard background="light-navy" class="companies d-flex flex-column mt-6 mb-4 p-5">
    <h5 class="navy mb-5">Companies using this Flight Template</h5>
    <div class="logos d-flex flex-wrap justify-content-start">
      <img v-for="c in companies"
        :key="c"
        :src="getCompanyLogoUrl(c)"
        :alt="c.replace(/\b\w/g, c => c.toUpperCase()) + ' Logo'"
      >
    </div>
  </BaseCard>
</template>

<script>
export default {
  name: 'FlightTemplateCompanies',

  props: {
    companies: { type: Array, required: true },
  },

  methods: {
    getCompanyLogoUrl(company) {
      const logo = `/images/companies/${company}-navy.svg`;
      return logo;
    },
  },
};
</script>

<style lang="less" scoped>

.companies {
  max-width: 35rem;

  h5 { text-transform: uppercase }

  img {
    max-width: 6rem;
    margin-right: 3rem;
    margin-bottom: 2rem;
  }
}
</style>
