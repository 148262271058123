<template>
  <section class="row">
    <form class="col-7">
      <h2 class="mb-5">What do you want to ask?</h2>
        <div
          class="row question"
          v-for="(question, index) in questions"
          :key="index">
          <div
            class="col-11 input-group"
            :class="{ invalid: !question.text, focus: isQuestionFocused(index) }">
            <ExpandingTextarea
              v-model.trim="question.text"
              :state="question.text.length > 0"
              :id="`question-${index}`"
              :data-testid="`question-${index}`"
              :data-tour="`question-field-${index+1}`"
              :ref="`question-${index}`"
              placeholder="Write your question here"/>
              <div class= "error">
                <icon name="remove" color="orange" width="7" height="7"/>
                <span>Question {{ index + 1 }} can not be empty</span>
              </div>
              <label :for="'question-' + index" class="info-label mr-4">
                Question {{ index + 1 }}
              </label>
          </div>

          <div class="col-1 d-flex">
            <button
              @click.prevent="removeQuestion(index)"
              v-if="allowRemove"
              :disabled="questions.length <= 1"
              :aria-label="`Remove question ${index}`"
              :ref="`remove-button-${index}`"
              :data-testid="'remove-question-' + index">
                <icon name="remove" width="7" height="7"/>
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <button
              @click.prevent="addQuestion"
              v-if="allowAdd"
              ref="addQuestionButton"
              :disabled="!canAskNewQuestion"
              data-testid="addQuestionButton"
              class="secondary my-4 d-flex align-items-center">
              <icon name="add" width="7" height="7"/>
              <span>Ask another question</span>
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col-11 mt-6">
            <Button
              class="lg primary"
              @click.native="saveSection"
              data-testid="doneEditQuestions"
              :pending="isLoading"
              :disabled="!canSaveSection">
                {{ editMode ? 'Update' : 'Save & Continue' }}
            </Button>

            <Button
              type="reset"
              @click.native.prevent="cleanSection"
              class="lg tertiary mt-4">
              Clear
            </Button>
          </div>
        </div>
    </form>

    <aside class="col-3 offset-1">
      <Tip>
        We recommend asking no more than 5 questions.
      </Tip>
    </aside>
  </section>
</template>

<script>
import { deepEquals } from '@balloonplatform/client-lib/lib/js/utils';

export default {
  name: 'FlightQuestionsSection',

  props: {
    defaultQuestions: { type: Array, default: () => [] },
    allowAdd: { type: Boolean, default: true },
    allowRemove: { type: Boolean, default: true },
    editMode: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      questions: [],
    };
  },

  computed: {
    canAskNewQuestion() {
      return this.questions.every(({ text }) => text.length > 0);
    },

    canSaveSection() {
      if (!this.canAskNewQuestion) {
        return false;
      }

      if (!this.editMode) {
        return true;
      }

      return !deepEquals(this.questions, this.defaultQuestions);
    },
  },

  watch: {
    questions: {
      handler(newVal, oldVal) {
        if (oldVal[0] && this.haveQuestionsChanged()) {
          this.$emit('dirty');
          return;
        }

        this.$emit('clean');
      },
      deep: true,
    },
  },

  created() {
    this.setQuestions();
  },

  mounted() {
    this.focusQuestion();
  },

  methods: {
    saveSection() {
      this.$emit('save', {
        key: 'questions',
        section: { questions: this.questions },
      });

      this.isSaved = true;
    },

    cleanSection() {
      this.$emit('clean');
      this.setQuestions();
    },

    setQuestions() {
      this.questions = this.defaultQuestions.map((question) => ({ ...question }));
    },

    addQuestion() {
      this.questions.push({ text: '' });
      this.focusQuestion();
    },

    removeQuestion(index) {
      this.questions.splice(index, 1);
    },

    focusQuestion() {
      const focusIndex = this.questions.length - 1;
      this.$nextTick(() => this.$refs[`question-${focusIndex}`][0].$refs.textarea.focus());
    },

    isQuestionFocused(index) {
      const question = this.$refs[`question-${index}`];
      if (!question || !question[0]) {
        return true;
      }
      return question[0].$refs.textarea === document.activeElement;
    },

    haveQuestionsChanged() {
      if (this.questions.length !== this.defaultQuestions.length) {
        return true;
      }

      for (let i = 0; i < this.questions.length; i += 1) {
        if (this.questions[i].text !== this.defaultQuestions[i].text) {
          return true;
        }
      }

      return false;
    },
  },
};
</script>

<style lang="less" scoped>
.question {
  &:first-child {
    margin-top: 2.5rem;
  }

  margin: 0;

  .input-group {
    padding: 1.1rem 1.2rem;
    border: solid 1px @light-navy;
  }

  textarea {
    margin-top: 1rem;
    padding: 0;

    &:focus {
      border: none;
    }
  }

  label {
    color: @teal;
    font-size: 1rem;
    font-weight: 900;
    line-height: 1.4;
    letter-spacing: normal;
    text-transform: none;
    position: static;
  }

  button {
    margin: 0;
  }
}

button {
  svg {
    margin-right: 1rem;
  }
}

.tip {
  margin-top: 13rem;
}

.input-group {
  &.focus {
    border-bottom: 2px solid @teal;
  }
}
</style>
