<template>
  <section class="row">
    <form class="col">
      <div class="row">
        <div class="col-7">
          <h2>Give your flight a name.</h2>
          <div class="input-group" :class="{'invalid': $v.name.$error }">
            <b-input
              aria-label="Name"
              data-testid="name"
              class="bordered"
              lazy-formatter
              placeholder="Name"
              type="text"
              ref="nameInput"
              v-model.trim="$v.name.$model"/>
            <div class= "error">
              <icon name="remove" color="orange" width="7" height="7"/>
              <span v-if="!$v.name.required">The name is required</span>
              <span v-if="!$v.name.minLength">
                Name must have at least {{ $v.name.$params.minLength.min }} letters.
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <h2 class="mt-6">Write a description for your flight.</h2>
          <div class="row">
            <div class="col-7">
              <div class="input-group">
                <ExpandingTextarea
                  class="bordered"
                  data-testid="description"
                  aria-label="Description"
                  placeholder="Description"
                  ref="descriptionInput"
                  v-model="description"
                  :rows="2"/>
              </div>
            </div>
            <div class="col offset-1">
              <Tip>Provide more context for your flight.</Tip>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-7 mt-6">
          <Button
            class="lg primary"
             @click.native="saveSection"
            :disabled="!canSaveSection"
            :is-loading="isLoading"
            data-testid="doneEditName">
            {{ editMode ? 'Update' : 'Save & Continue' }}
          </Button>

          <Button
            type="reset"
            @click.native.prevent="cleanSection"
            class="tertiary lg mt-4">
            Clear
          </Button>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';

export default {
  name: 'FlightNameSection',

  mixins: [validationMixin],

  props: {
    defaultName: { type: String, default: '' },
    defaultDescription: { type: String, default: '' },
    editMode: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      name: this.defaultName,
      description: this.defaultDescription,
    };
  },

  validations: {
    name: {
      required,
      minLength: minLength(2),
    },
  },

  computed: {
    canSaveSection() {
      if (this.$v.$invalid) {
        return false;
      }

      if (this.editMode
        && this.name === this.defaultName
        && this.description === this.defaultDescription) {
        return false;
      }

      return true;
    },
  },

  watch: {
    name(newName, oldName) {
      if (newName !== oldName && newName !== this.defaultName) {
        this.$emit('dirty');
        return;
      }

      if (newName === this.defaultName) {
        this.$emit('clean');
      }
    },

    description(newDescription, oldDescription) {
      if (newDescription !== oldDescription && newDescription !== this.defaultDescription) {
        this.$emit('dirty');
      }

      if (newDescription === this.defaultDescription) {
        this.$emit('clean');
      }
    },
  },

  methods: {
    saveSection() {
      if (this.$v.$invalid) { return; }

      this.$emit('save', {
        key: 'name',
        section: {
          name: this.name,
          description: this.description,
        },
      });
    },

    cleanSection() {
      this.$emit('clean');
      this.name = this.defaultName;
      this.description = this.defaultDescription;
    },
  },
};
</script>

<style lang="less" scoped>
.tip {
  margin-top: 5rem;
}
</style>
