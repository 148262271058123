<template>
  <nav :class="showSidebar ? 'open' : 'close'">
    <button
      data-tour="expand-sidebar"
      class="sidebar-toggle"
      @click="showSidebar = !showSidebar">
      <icon name="chevron" />
    </button>

    <template v-if="showSidebar">
      <FlightSidebarName :flight="flight" :can-export="canExportFlight" />
      <FlightSidebarShortcode :flight="flight" />
      <FlightSidebarFlightTemplate v-if="flight.templateId" :template-id="flight.templateId" />
      <FlightSidebarStage :flight="flight" />
      <FlightSidebarParticipation v-if="canSeeParticipants" :flight="flight" />
    </template>

    <div class="row mt-5" v-if="canEditFlight">
      <div class="col">
        <Button
          :to="{ name: 'flight-edit', params: { id: flight.id } }"
          data-tour="edit-flight-button"
          class="lg primary">
          Edit Flight
        </Button>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import FlightSidebarName from '@/components/FlightSidebar/FlightSidebarName.vue';
import FlightSidebarStage from '@/components/FlightSidebar/FlightSidebarStage.vue';
import FlightSidebarShortcode from '@/components/FlightSidebar/FlightSidebarShortcode.vue';
import FlightSidebarParticipation from '@/components/FlightSidebar/FlightSidebarParticipation.vue';
import FlightSidebarFlightTemplate from '@/components/FlightSidebar/FlightSidebarFlightTemplate.vue';

import flightDetail from '@/mixins/flight-detail';

export default {
  name: 'FlightSidebar',

  mixins: [flightDetail],

  components: {
    FlightSidebarName,
    FlightSidebarStage,
    FlightSidebarShortcode,
    FlightSidebarParticipation,
    FlightSidebarFlightTemplate,
  },

  props: {
    value: { type: Boolean },
    flight: { type: Object, required: true },
  },

  data() {
    return {
      showSidebar: true,
    };
  },

  computed: {
    ...mapGetters([
      'isMyFlight',
      'canAccessResults',
      'canAccessPreflight',
    ]),

    canEditFlight() {
      return this.isCredentialed && this.isMyFlight(this.flight.id) && !this.isPostFlight;
    },

    canExportFlight() {
      return this.isCredentialed && this.isPostFlight && this.canAccessResults(this.flight.id);
    },

    canSeeParticipants() {
      return (this.canAccessResults(this.flight.id) && this.canAccessPreflight(this.flight.id));
    },
  },

  watch: {
    showSidebar(newVal) {
      this.$emit('input', newVal);
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep svg {
  path {
    fill: none;
  }
}

::v-deep section {
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: solid 1px @tan;

  &:last-of-type {
    padding-bottom: 0;
    border-bottom: none;
  }
}

nav {
  transform: translateX(-100%);
  margin-left: -15px;
  height: 100%;
  background-color: @medium-tan !important;
  padding: 0 3rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;

  ::v-deep h5 {
    font-size: 1rem;
    font-weight: 900;
    letter-spacing: 2px;
    margin: 0 0 1rem 0;
    color: @green;
    text-transform: uppercase;
  }

  transition: all .2s ease-in-out;

  .sidebar-toggle {
    margin-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 1px;
    background-color: @pink;
    position: absolute;
    right: 15px;
    top: 1.4rem;
    z-index: 1000;
    transform: translateX(100% + 10px);
    transition: transform .4s ease-in-out;

    &:hover {
      svg {
        height: 17px !important;
        width: 17px !important;
        transition: height width .1s linear;
      }
    }

    svg {
      transition: transform .5s ease-in-out;
      transform: rotate(180deg)
    }
  }

  &.close {
    .sidebar-toggle {
      padding-left: .5rem;

      &:hover {
        transition: all .1s ease;
        width: 70px;
        transform: translateX(100% + 20px);

        &::before {
          content: "Open";
          color: @white;
          font-weight: 500;
          margin-right: 1rem;
        }
      }
    }
  }

  &.open {
    transform: translateX(0);

    .sidebar-toggle {
      transform: translateX(17px);
      svg {
        transform: rotate(0deg)
      }
    }
  }
}
</style>
