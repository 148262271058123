<template>
  <div class="my-3">
    <Label class="question" v-html="parseQuestion(question)" text=""/>
  </div>
</template>

<script>
export default {
  name: 'FlightTemplateQuestion',

  props: {
    question: { type: String, required: true },
  },

  methods: {
    parseQuestion(q) {
      const placeholder = /(#{.*?})/g;
      const openChar = /#{/g;
      const endChar = /}/g;
      const openTag = '<span>';
      const endTag = '</span>';

      if (placeholder.test(q)) {
        return q.replace(openChar, openTag).replace(endChar, endTag);
      }
      return q;
    },
  },
};
</script>

<style lang="less" scoped>
.question {
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.7;
  box-decoration-break: clone;

  ::v-deep span {
    font-size: inherit;
    background-color: rgba(157, 203, 194, 0.2);
    padding: 0.3rem .5rem;
    border: solid 1px #9dcbc2;
  }
}
</style>
