<template>
  <article>
    <div class="row pt-5">
      <div class="col-10 pl-0" v-if="title">
        <h1>{{ title }} Flights <small>0</small></h1>
      </div>
      <div class="col-10 bg-medium-tan text-center py-5">
        <h4 v-if="title">No {{ title.toLowerCase() }} flights right now!</h4>
        <h4 v-else>No selected flights</h4>
        <p
          v-if="!isActiveSubscription && (title === 'Live' || title === 'Upcoming')"
          class="mt-1"
        >
          Reactivate your subscription to start your own flight at any time.
        </p>
        <p class="mt-1" v-else-if="text">
          {{ text }}
        </p>
        <router-link
          tag="button"
          class="primary mt-4"
          :to="{ name: 'flight-new' }"
          :disabled="!isActiveSubscription"
          v-if="showButton"
        >
          Start a flight
        </router-link>
      </div>
    </div>
  </article>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FlightListEmpty',

  computed: {
    ...mapGetters(['isActiveSubscription']),
  },

  props: {
    title: { type: String, required: false },
    text: { type: String, required: false },
    showButton: { type: Boolean, required: false },
  },
};
</script>

<style lang="less" scoped>
  h1 {
    margin-bottom: 2.9rem;

    small {
      margin-left: 2.4rem;
      font-size: 2.2rem;
      font-weight: normal;
    }
  }
</style>
