<template>
  <div class="row flights-nav flex-shrink-0 d-flex justify-content-between
    align-items-center">
    <div class="col-auto offset-2">
      <div class="row py-5">
        <b-checkbox
          :checked="areAllSelected"
          :disabled="areAllSelected"
          @change="selectAll"
          class="mr-5"
        >
          All
        </b-checkbox>
        <b-checkbox
          v-model="selectedOptions"
          v-for="option in options"
          data-testid="flight-list-header"
          :key="option.title"
          :value="option.key"
          :disabled="selectedOptions.includes(option.key) && selectedOptions.length == 1"
          class="d-flex mr-5"
        >
          {{option.title}}
        </b-checkbox>
      </div>
    </div>
    <div v-if="loggedInUserCanStartFlight && isActiveSubscription"
      class="col d-flex justify-content-end align-items-center"
    >
      <router-link
        :to="{ name: 'flight-new'}"
        tag="button"
        data-tour="start-flight"
        class="primary start-a-flight-button">
          Start A Flight
        </router-link>
    </div>
    <div class="col-1"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import flightList from '@/mixins/flight-list';

export default {
  name: 'FlightListHeader',
  mixins: [flightList],

  props: {
    value: { type: Array, required: true },
    options: { type: Array, required: true },
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      selectedOptions: [],
    };
  },

  computed: {
    ...mapGetters(['isActiveSubscription', 'loggedInUserCanStartFlight']),

    areAllSelected() {
      return this.selectedOptions.length === this.options.length;
    },
  },

  watch: {
    selectedOptions(newVal) {
      this.$emit('input', newVal);
    },
  },

  methods: {
    selectAll() {
      this.selectedOptions = this.options.map((option) => option.key);
    },
  },

  created() {
    this.selectAll();
  },
};
</script>
