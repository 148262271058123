<template>
  <div class="row">
    <div class="col d-flex flex-column mb-4">
      <h2 class="mt-8">Review your flight</h2>

      <section class="d-flex flex-column mt-6">
        <h5 class="green upper mb-3">Name & Description</h5>
        <span class="balloon-text">{{flight.name}}</span>
        <span class="balloon-text">{{flight.description}}</span>
      </section>

      <section class="d-flex flex-column mt-6">
        <h5 class="green upper mb-3">Questions</h5>
        <div
          class="d-flex flex-column mb-4"
          v-for="(question, index) in flight.questions"
          :key="index">
            <small class="author-name">Question {{ index + 1 }}</small>
            <span class="balloon-text">{{ question.text }}</span>
        </div>
      </section>

      <section class="d-flex flex-column mt-6">
        <h5 class="green upper mb-3">Participants</h5>
        <span class="balloon-text" v-if="flight.useShortlink">Anyone with the shortlink</span>
        <span class="balloon-text" v-else>
          {{ userCount === 1 ? '1 Person' : `${userCount} People` }}
        </span>
      </section>

      <section class="d-flex flex-column mt-6">
        <h5 class="green upper mb-3">Timing</h5>
        <div class="d-flex flex-column mb-4">
          <small class="author-name">Stage 1 Start</small>
          <span class="balloon-text">
            {{ formatDate(flight.stages.one.startDate, 'dddd, MMMM D, YYYY [at] h[:]mma') }}
          </span>
        </div>
        <div class="d-flex flex-column mb-4">
          <small class="author-name">Stage 2 Start</small>
          <span class="balloon-text">
            {{ formatDate(flight.stages.two.startDate, 'dddd, MMMM D, YYYY [at] h[:]mma') }}
          </span>
        </div>
        <div class="d-flex flex-column mb-4">
          <small class="author-name">Flight End</small>
          <span class="balloon-text">
            {{ formatDate(flight.stages.two.endDate, 'dddd, MMMM D, YYYY [at] h[:]mma') }}
          </span>
        </div>
      </section>

      <section class="d-flex flex-column mt-6">
        <h5 class="green upper mb-3">Options</h5>
        <span class="balloon-text">
          {{
            flight.showAuthor
              ? 'Your name will be shown as flight creator'
              : 'Your name will remain anonymous'
          }}
        </span>

         <span class="balloon-text">
          {{
            flight.hasPrivateResults
              ? 'Only flight creator will be able to see flight results'
              : 'All participants will be able to see flight results'
          }}
        </span>
      </section>
    </div>
  </div>
</template>

<script>
import dateFns from '@/mixins/date-fns';

export default {
  name: 'FlightReview',
  mixins: [dateFns],

  props: {
    flight: { type: Object, required: true },
    userCount: { type: Number, required: true },
  },
};
</script>
