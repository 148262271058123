export function copyTextFromInput(document, ele) { //eslint-disable-line
  ele.focus();
  ele.select();

  try {
    const successful = document.execCommand('copy');
    ele.blur();
    return successful;
  } catch (err) {
    ele.blur();
    return false;
  }
}
