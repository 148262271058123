<template>
  <section>
    <BaseCard tabindex="0"
      class="featured-template-card w-100 mb-5 d-flex flex-column justify-content-start"
      :style="{background: `url(${template.heroImageUrl}) right / cover no-repeat,
        linear-gradient(90deg, #154f4a 5%, #e3b8ae 95%)center / cover no-repeat`}"
    >
      <h5 class="text-uppercase pink mb-3">Featured Flight Template</h5>
      <h1 class="mb-4">{{templateAuthor.fullName}}</h1>
      <h3 class="w-50">{{template.name}}</h3>
    </BaseCard>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FlightTemplateFeaturedCard',

  props: {
    template: { type: Object, required: true },
  },

  computed: {
    ...mapGetters(['templateAuthorById']),

    templateAuthor() { return this.templateAuthorById(this.template.templateAuthorId); },
  },
};
</script>

<style lang="less" scoped>
.featured-template-card {
  height: 280px;
  background-size: cover;
  background-position: center;
  padding: 3.2rem;
  padding-top: 7rem;
  cursor: pointer;
}

h1, h3 { color: @tan }
</style>
