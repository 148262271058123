<template>
  <section class="row">
    <form class="col-7">
      <div class="row" v-for="(option, key, index) in options" :key="key">
        <div class="col" :class="{ 'mt-7': index }">
          <h2 class="mb-4">{{ option.title }}</h2>
          <b-checkbox :ref="`${key}Input`"  v-model="option.value">{{ option.label }}</b-checkbox>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col">
          <Button
            class="lg primary"
            @click.native="saveSection"
            :is-loading="isLoading"
            :disabled="!canSaveSection"
            data-testid="doneEditOptions">
              {{ editMode ? 'Update' : 'Save & Continue' }}
          </Button>

          <Button
            type="reset"
            @click.native.prevent="cleanSection"
            class="lg tertiary mt-4">
            Clear
          </Button>
        </div>
      </div>
    </form>
    <aside
      v-if="options.hasPrivateResults"
      class="col offset-1"
    >
      <Tip>
        Balloon encourages transparency and collaboration so we recommend keeping your flight
        results open.
        However, you may consider keeping the results private when launching a flight with external
        participants.
      </Tip>
    </aside>
  </section>
</template>

<script>
export default {
  name: 'FlightOptionsSection',

  props: {
    defaultOptions: {
      type: Object,
      default: () => ({
        hasPrivateResults: false,
        showAuthor: false,
      }),
    },
    editMode: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      options: {
        showAuthor: {
          title: 'Do you want to show your name as flight creator?',
          label: 'Yes, show my name',
          value: false,
        },
        hasPrivateResults: {
          title: 'Do you want keep flight results private?',
          label: 'Yes, keep it private',
          value: false,
        },
      },
    };
  },

  computed: {
    hasSectionChanged() {
      let hasChanged = false;

      Object.keys(this.defaultOptions).forEach((key) => {
        if (this.options[key].value !== this.defaultOptions[key]) {
          hasChanged = true;
        }
      });

      return hasChanged;
    },

    canSaveSection() {
      if (this.editMode) {
        return this.hasSectionChanged;
      }

      return true;
    },
  },

  watch: {
    options: {
      handler(newOptions) {
        let isClean = true;
        Object.keys(newOptions).forEach((key) => {
          if (newOptions[key].value !== this.defaultOptions[key]) {
            isClean = false;
            this.$emit('dirty');
          }
        });

        if (isClean) {
          this.$emit('clean');
        }
      },

      deep: true,
    },
  },

  created() {
    this.setOptions();
  },

  methods: {
    saveSection() {
      const section = {};
      Object.keys(this.options).forEach((key) => {
        section[key] = this.options[key].value;
      });

      this.$emit('save', {
        key: 'options',
        section,
      });
    },

    setOptions() {
      Object.keys(this.defaultOptions).forEach((key) => {
        if (this.options[key]) {
          this.options[key].value = this.defaultOptions[key];
        }
      });
    },

    cleanSection() {
      this.$emit('clean');
      this.setOptions();
    },
  },
};
</script>

<style lang="less" scoped>
.tip { margin-top: 22rem }
</style>
