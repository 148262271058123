<template>
  <div class="container-fluid h-100 d-flex flex-column">
    <div class="row">
      <div class="col py-5 text-center">
        <div class="no-flights-img"></div>
        <div v-if="noAccess">
          <h3 class="medium-navy">Sorry, this flight is no longer available.</h3>
          <h2>This flight may only be visible to an audience you are not in.</h2>
        </div>
        <div v-else>
          <h3 class="medium-navy">Sorry, this flight is not available at this time.</h3>
          <h2>The timing of this flight has likely changed.
            You will receive an email when it starts.</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'FlightsEmptyView',
  data() {
    return {};
  },
  computed: {
    ...mapState({
      noAccess: (state) => state.flights.noAccess,
    }),
  },
};
</script>

<style lang="less" scoped>
.no-flights-img{
  height: 300px;
  background: url('/images/no_flights.svg') center no-repeat;
}
</style>
