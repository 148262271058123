<template>
  <section class="row mb-3">
    <template v-if="stage === 'one'">
      <div class="col-8 pl-0 d-flex justify-content-between">
        <p class="instructions">
          You are in
          <b>{{ commentMode ? 'Collaboration Mode.' : 'Solo Mode.' }}</b>
          {{
            commentMode
            ? 'Participants can see all balloons, which remain anonymous.'
            : 'Participants can only see their balloons.'
          }}
        </p>
      </div>
      <div class="col-2 d-flex justify-content-end pr-0">
        <ToggleSwitch v-model="commentMode" data-tour="comment-mode">
            {{ commentMode ? 'Hide other responses' : 'Show other responses' }}
        </ToggleSwitch>
      </div>
    </template>

    <template v-if="stage === 'two'">
      <div class="col-6 pl-0">
        <p>
          You are in Pump Mode. Pump the balloons you support. Other pumps are hidden.
        </p>
      </div>

      <div class="col-4 d-flex justify-content-end">
        <b class="mr-4">1&nbsp;Pump =&nbsp;Support</b>
        <b>2&nbsp;Pumps =&nbsp;Strongly&nbsp;Support</b>
      </div>
    </template>
  </section>
</template>

<script>
export default {
  name: 'LiveFlightInstructions',

  props: {
    value: { type: Boolean, default: false },
    stage: { type: String, required: true },
  },

  data() {
    return {
      commentMode: this.value,
    };
  },

  watch: {
    commentMode(newVal) {
      this.$emit('input', newVal || false);
    },

    value(newVal) {
      this.commentMode = newVal;
    },
  },
};
</script>

<style lang="less" scoped>
  ::v-deep .thumb {
    top: 7px;
  }
</style>
