<template>
  <div class="scroll-wrapper" v-bar="{ scrollThrottle: 30 }">
    <div class="scroll-content">
      <LoadingBalloonAnimation v-if="isFlightLoading" />
      <div v-else class="container-fluid h-100">

        <div class="row h-100">
          <div class="col-3">
            <FlightFormSidebar
              :edit-mode="true"
              :flight-id="flight.id"
              :flight-sections="flightSections"
              :selected-flight-section="selectedFlightSection"
              @select-flight-section="selectFlightSection"/>
          </div>

          <div class="col offset-1 pb-5">
            <FlightNameSection
              v-if="selectedFlightSection === 'name'"
              ref="nameSection"
              :default-name="flight.name"
              :default-description="flight.description"
              :edit-mode="true"
              :is-loading="isLoading"
              @clean="setSectionClean('name')"
              @dirty="setSectionNotSaved('name')"
              @save="updateFlight"/>

            <FlightQuestionsSection
              v-if="selectedFlightSection === 'questions'"
              ref="questionsSection"
              :default-questions="flight.questions"
              :edit-mode="true"
              :is-loading="isLoading"
              @clean="setSectionClean('questions')"
              @dirty="setSectionNotSaved('questions')"
              @save="updateFlight"/>

            <FlightParticipantsSection
              v-if="selectedFlightSection === 'participants'"
              ref="participantsSection"
              :default-user-ids="flight.users.invited.userIds"
              :default-segment-ids="flight.users.invited.segmentsId"
              :default-emails="flight.users.invited.emails"
              :default-domain-lock="flight.excludeExternalContributors"
              :edit-mode="true"
              :has-shortlink="!!flight.shortcode"
              :is-loading="isLoading"
              @clean="setSectionClean('participants')"
              @dirty="setSectionNotSaved('participants')"
              @save="updateFlight"
              @create-segment="updateSaveSegment"/>

            <FlightTimingSection
              v-if="selectedFlightSection === 'timing'"
              ref="timingSection"
              :default-stages="flight.stages"
              :edit-mode="true"
              :is-loading="isLoading"
              @clean="setSectionClean('timing')"
              @dirty="setSectionNotSaved('timing')"
              @save="updateFlight"/>

            <FlightOptionsSection
              v-if="selectedFlightSection === 'options'"
              ref="optionsSection"
              :default-options="{
                showAuthor: flight.author.show,
                hasPrivateResults: flight.hasPrivateResults,
              }"
              :edit-mode="true"
              :is-loading="isLoading"
              @clean="setSectionClean('options')"
              @dirty="setSectionNotSaved('options')"
              @save="updateFlight"/>
          </div>
        </div>
      </div>
    </div>
    <LeaveGuard />
  </div>
</template>

<script>
import flighData from '@/mixins/flight-data';
import flightForm from '@/mixins/flight-form';

export default {
  name: 'FlightEditView',
  mixins: [flightForm, flighData],

  data() {
    return {
      editMode: true,
      segmentData: {},
    };
  },

  computed: {
    flightStage() {
      return this.flight.id ? this.currentStage(this.flight.id) : null;
    },
  },

  watch: {
    activeFlight() {
      this.flight = this.activeFlight;
    },
  },

  async created() {
    await this.fetchDelayedData();
    this.segmentData = { create: false, name: '' };
    this.flightSections.forEach((s) => {
      s.completed = true; // eslint-disable-line
    });
  },

  methods: {
    async updateFlight({ key, section }) {
      if (key === 'options') {
        section = { // eslint-disable-line
          author: { show: section.showAuthor },
          hasPrivateResults: section.hasPrivateResults,
        };
      }

      if (key === 'participants') {
        section = { // eslint-disable-line
          excludeExternalContributors: section.excludeExternalContributors,
          users: {
            invited: {
              userIds: section.userIds,
              segmentIds: section.segmentIds,
              emails: section.emails,
              useShortlink: section.useShortlink,
            },
          },
        };
      }

      this.updateSectionData({ key, section });

      if (this.isLoading) {
        return;
      }

      try {
        if (this.flightStage === 'postFlight') {
          const logInfo = {
            flightEnd: new Date(this.flight.stages.two.endDate),
            current: new Date(),
          };
          // eslint-disable-next-line
          console.group('Attempt to edit flight that has ended');
          // eslint-disable-next-line
          console.log('Stage 2 ended at: ', logInfo.flightEnd);
          // eslint-disable-next-line
          console.log('Current local time: ', logInfo.current);
          // eslint-disable-next-line
          console.groupEnd();
          this.$store.dispatch('logErrorToFS', `
            Attempt to edit flight that has ended
            Stage 2 ended at: ${logInfo.flightEnd},
            Current local time: ${logInfo.current}
          `);
          this.$store.dispatch('errorToast', 'You may not edit flights once they have ended.');
          return;
        }

        this.isLoading = true;
        const updatedFlight = await this.$store.dispatch('updateFlight', [this.flight.id, this.flight]);

        if (this.segmentData.create) {
          const segmentData = {
            criteria: null,
            name: this.segmentData.name,
            emails: [],
            userIds: [...updatedFlight.users.invited.userIds],
            segmentIds: [...updatedFlight.users.invited.segmentIds],
            isSaved: true,
          };

          await this.$store.dispatch('createSegment', segmentData);
          this.$store.dispatch('successToast', 'Audience saved as segment!');
        }

        this.$store.dispatch('successToast', `Your flight ${key} was saved correctly`);
      } catch (error) {
        this.$store.dispatch('errorToast', 'There was a problem editing your flight.');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
section.col {
  padding-left: 0;
}

::v-deep form {
  padding-top: 7rem;
}
</style>
