<template>

  <div class="container-fluid h-100">
    <LoadingBalloonAnimation v-if="isFlightLoading" />
    <div class="row h-100" v-else>
      <div :class="showSidebar ? 'col-3' : 'col-1'">
        <FlightSidebar
          :flight="activeFlight"
          v-model="showSidebar" />
      </div>

      <div class="col" :class="{'offset-1': showOffset}">
        <template v-if="hasAccess">
          <LiveFlightQuestionHeader
            :flight="activeFlight"
            v-model="presentationMode"/>

          <LiveFlightInstructions
            v-if="isStageOne || isStageTwo"
            ref="liveFlightInstructions"
            :stage="stage"
            v-model="commentMode"/>

          <PostFlightBalloonList
            v-if="isPostFlight"
            class="p2"
            :balloons="balloons"
            :presentation-mode="presentationMode"/>

          <LiveFlightBalloonList
            v-else
            :class="isStageOne ? 'p10' : 'p2'"
            :balloons="balloons"
            :comment-mode="commentMode"/>

          <LiveFlightBalloonForm
            v-if="isStageOne"
            :flight="activeFlight"
            :show-sidebar="showSidebar"/>
        </template>
        <FlightDetailPrivateResults v-else-if="!canAccessResults(activeFlight.id)"/>
        <FlightDetailPreFlight v-else-if="!canAccessPreflight(activeFlight.id)"/>

         <LiveFlightStateModal
          v-if="showStateChangeDialog"
          :stage="stage"
          @close="showStateChangeDialog=false"/>
      </div>
    </div>
  </div>
</template>

<script>
import SeedRandom from 'seedrandom';
import { mapState, mapGetters } from 'vuex';

import refreshIntervals from '@/services/intervals';

import FlightSidebar from '@/components/FlightSidebar/FlightSidebar.vue';
import LiveFlightQuestionHeader from '@/components/LiveFlight/LiveFlightQuestionHeader.vue';
import LiveFlightBalloonForm from '@/components/LiveFlight/LiveFlightBalloonForm.vue';
import LiveFlightInstructions from '@/components/LiveFlight/LiveFlightInstructions.vue';
import LiveFlightStateModal from '@/components/LiveFlight/LiveFlightStateModal.vue';
import LiveFlightBalloonList from '@/components/LiveFlight/LiveFlightBalloonList.vue';
import PostFlightBalloonList from '@/components/PostFlight/PostFlightBalloonList.vue';
import FlightDetailPrivateResults from '@/components/FlightDetail/FlightDetailPrivateResults.vue';
import FlightDetailPreFlight from '@/components/FlightDetail/FlightDetailPreflight.vue';

import flighData from '@/mixins/flight-data';
import flightDetail from '@/mixins/flight-detail';

export default {
  name: 'FlightDetailView',

  mixins: [flightDetail, flighData],

  components: {
    FlightSidebar,
    FlightDetailPrivateResults,
    LiveFlightQuestionHeader,
    LiveFlightBalloonList,
    LiveFlightInstructions,
    LiveFlightBalloonForm,
    LiveFlightStateModal,
    PostFlightBalloonList,
    FlightDetailPreFlight,
  },

  data() {
    return {
      showSidebar: true,
      commentMode: false,
      presentationMode: false,
      showStateChangeDialog: false,
    };
  },

  computed: {
    ...mapState({
      activeQuestionId: (state) => state.flights.activeQuestionId,
      questionsList: (state) => state.flights.questionsList,
    }),

    ...mapGetters([
      'isCredentialed',
      'activeQuestion',
      'balloonsForQ',
      'canAccessResults',
      'canAccessPreflight',
      'loggedInUser',
    ]),

    showOffset() {
      return this.showSidebar && this.hasAccess;
    },

    hasAccess() {
      return this.canAccessResults(this.activeFlight.id)
        && this.canAccessPreflight(this.activeFlight.id);
    },

    balloons() {
      if (!this.activeQuestion) {
        return [];
      }

      let balloons = this.balloonsForQ(this.activeQuestion.id);

      // If the user is currently in stage two, shuffle the balloons list
      // using seeded random values. This will keep the balloons in a consistent
      // order that varies by user.
      if (this.isStageTwo) {
        const random = SeedRandom(this.loggedInUser.id + this.activeFlight.id);
        balloons = balloons
          .map((b) => ({ key: random(), balloon: b }))
          .sort((a, b) => a.key - b.key)
          .map((e) => e.balloon);
      }

      return balloons;
    },
  },

  watch: {
    async $route(to, from) {
      if (to.params.id !== from.params.id) {
        this.fetchData();
      }
    },

    stage(newStage, oldStage) {
      if (this.$route.params.s === newStage) {
        return;
      }

      this.$router.replace({ name: 'flight-detail', params: { id: this.$route.params.id, s: newStage } });

      if (newStage === 'preFlight') {
        return;
      }

      this.showStateChangeDialog = true;

      if (oldStage === 'one' || oldStage === 'two') {
        this.stageChanged = true;
        this.fetchData();
      }

      if (newStage === 'postFlight') {
        refreshIntervals.clear();

        if (!this.isFlightLoading) {
          this.fetchDelayedData();
        }
      }
    },
  },

  async created() {
    await this.fetchDelayedData();
  },

  mounted() {
    if (this.stage !== 'postFlight') {
      refreshIntervals.set(
        () => this.fetchFlightData(),
        () => this.fetchBalloonData(),
      );
    }
  },

  beforeDestroy() {
    refreshIntervals.clear();
  },

  destroyed() {
    this.$store.dispatch('clearActiveFlight');
  },
};
</script>

<style lang="less" scoped>
.p10 {
  padding-bottom: 10rem;
}

.p2 {
  padding-bottom: 2rem;
}
</style>
