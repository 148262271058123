import { addMinutes } from 'date-fns';

import LeaveGuard from '@/components/LeaveGuard.vue';

import FlightFormSidebar from '@/components/FlightForm/FlightFormSidebar.vue';
import FlightNameSection from '@/components/FlightForm/FlightNameSection.vue';
import FlightOptionsSection from '@/components/FlightForm/FlightOptionsSection.vue';
import FlightTimingSection from '@/components/FlightForm/FlightTimingSection.vue';
import FlightQuestionsSection from '@/components/FlightForm/FlightQuestionsSection.vue';
import FlightParticipantsSection from '@/components/FlightForm/FlightParticipantsSection.vue';

export default {
  components: {
    LeaveGuard,
    FlightFormSidebar,
    FlightNameSection,
    FlightTimingSection,
    FlightOptionsSection,
    FlightQuestionsSection,
    FlightParticipantsSection,
  },

  data() {
    return {
      isLoading: false,

      flight: {
        showAuthor: true,
        useShortlink: true,
        hasPrivateResults: false,
        description: '',
        name: '',
        questions: [{ text: '' }],
        templateId: '',
        segmentIds: [],
        emails: [],
        userIds: [],
        excludeExternalContributors: true,
        stages: {
          one: {
            startDate: new Date(),
            endDate: new Date(),
          },
          two: {
            startDate: new Date(),
            endDate: new Date(),
          },
        },
      },

      selectedFlightSection: null,

      flightSections: [
        {
          key: 'name', title: 'Name & Description', completed: false, saved: true,
        },
        { key: 'questions', completed: false, saved: true },
        { key: 'participants', completed: false, saved: true },
        { key: 'timing', completed: false, saved: true },
        { key: 'options', completed: false, saved: true },
      ],
    };
  },

  computed: {
    areSectionsValid() {
      return this.flightSections.every((s) => s.completed);
    },
  },

  created() {
    this.selectFlightSection(this.flightSections[0].key);
    this.setDefaultStages(20);
  },

  methods: {
    setDefaultStages(minutes) {
      const flightStart = this.flight.stages.one.startDate;
      const stageSplit = addMinutes(flightStart, minutes * (2 / 3));
      const flightEnd = addMinutes(flightStart, minutes);

      this.flight.stages.one.startDate = flightStart.toISOString();
      this.flight.stages.one.endDate = stageSplit.toISOString();
      this.flight.stages.two.startDate = stageSplit.toISOString();
      this.flight.stages.two.endDate = flightEnd.toISOString();
    },

    toggleReview(value) {
      this.isReviewing = value === (false || true) ? value : !this.isReviewing;
    },

    selectFlightSection(key) {
      const currentSection = this.flightSections.find((s) => s.key === this.selectedFlightSection);
      if (currentSection && !currentSection.saved) {
        this.$store.dispatch('showLeaveGuard', {
          message: 'You have unsaved changes, your changes will be lost if you continue. Do you still want to continue?',
          okHandler: () => {
            this.selectedFlightSection = key;
            currentSection.saved = true;
          },
        });

        return;
      }

      this.selectedFlightSection = key;
    },

    setSectionNotSaved(key) {
      const index = this.flightSections.findIndex((s) => s.key === key);
      this.flightSections[index].saved = false;
    },

    setSectionNotCompleted(key) {
      const index = this.flightSections.findIndex((s) => s.key === key);
      this.flightSections[index].completed = false;
    },

    setSectionClean(key) {
      const index = this.flightSections.findIndex((s) => s.key === key);
      this.flightSections[index].saved = true;
    },

    setSectionSaved(key) {
      const index = this.flightSections.findIndex((s) => s.key === key);
      if (index > -1) {
        this.flightSections[index].completed = true;
        this.flightSections[index].saved = true;

        if (this.editMode) {
          return;
        }

        if (this.areSectionsValid) {
          this.isReviewing = true;
          return;
        }

        if (index < this.flightSections.length - 1) {
          this.selectFlightSection(this.flightSections[index + 1].key);
          return;
        }

        const nextIncompleteSection = this.flightSections.findIndex((s) => !s.completed);
        this.selectFlightSection(this.flightSections[nextIncompleteSection].key);
      }
    },

    updateSectionData({ key, section }) {
      this.setSectionSaved(key);
      this.flight = Object.assign(this.flight, section);
    },

    updateSaveSegment({ saveSegment, newSegmentName }) {
      this.saveSegment = saveSegment;
      this.newSegmentName = newSegmentName;
    },
  },
};
