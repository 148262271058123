<template>
  <nav :class="{ disabled }">
    <div class="row">
      <div class="col">
        <BackToFlightsLink :flight-id="flightId" />
      </div>
    </div>

    <h1>{{ editMode ? 'Edit' : 'Start a' }} Flight</h1>

    <ul>
      <li
        v-for="i in flightSections"
        :key="i.key"
        :data-testid="'edit' + (i.key.charAt(0).toUpperCase() + i.key.slice(1))"
        :class="{
          selected: !disabled && selectedFlightSection === i.key,
          completed: i.completed }"
        @click="selectSection(i)">
          <div>
            <icon
              name="checkmark_complete"
              color="navy"
              width="10"
              height="8"
              v-show="i.completed"/>
            <span>{{ i.title  || i.key }}</span>
          </div>
          <div v-show="!i.saved" class="marker"></div>
      </li>
    </ul>

    <section v-if="flightTemplate && flightTemplate.id"
      class="flight-template d-flex flex-column">
      <p>
        You are using this flight template:
      </p>

      <FlightTemplateCard
        :template="flightTemplate"
        :author="templateAuthorById(flightTemplate.templateAuthorId)"
        size='md'
        class="mt-4 mb-5"
      />

      <div class="d-flex justify-content-between">
        <button @click="cancelTemplate" class="m-0 w-50 mr-3">Cancel flight template</button>
        <button @click="unlinkTemplate" class="m-0 w-50">Unlink flight template</button>

        <div class="hover-tip position-absolute mt-4" tabindex="0">
          <icon name="info-circle" width="12" height="12"/>
          <HoverTip class="p-4">
            <p class="font-weight-bold mb-2">Cancel flight template</p>
            <p>The flight template, name, description, and questions will be removed.</p>
            <hr class="my-4">
            <p class="font-weight-bold mb-2">Unlink flight template</p>
            <p>Flight template will not be displayed to participants.
              Content in the name, description, and questions will remain.</p>
          </HoverTip>
        </div>
      </div>
    </section>

  </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'FlightFormSidebar',

  props: {
    editMode: { type: Boolean, default: false },
    flightId: { type: String, default: null },
    selectedFlightSection: { type: String },
    disabled: { type: Boolean, default: false },
    flightSections: { type: Array, required: true },
    flightTemplate: { type: Object },
  },

  computed: {
    ...mapGetters(['templateAuthorById']),
  },

  methods: {
    ...mapActions(['clearActiveFlightTemplate']),

    selectSection({ key }) {
      if (this.disabled) { return; }
      this.$emit('select-flight-section', key);
    },

    unlinkTemplate() {
      this.$store.dispatch('showLeaveGuard', {
        message: 'Are you sure you want to unlink this flight template? The flight template will not be displayed to the flight participants. The content in the flight name, description, and question(s) field will remain.',
        cancelText: 'Continue with flight template',
        okText: 'Unlink flight template',
        okHandler: () => {
          this.$store.dispatch('clearActiveFlightTemplate');
          this.$emit('unlink-template');
        },
      });
    },

    cancelTemplate() {
      this.$store.dispatch('showLeaveGuard', {
        message: 'Are you sure you want to cancel this flight template? The flight Name, Description, and Questions will be removed.',
        cancelText: 'Continue with flight template',
        okText: 'Cancel flight template',
        okHandler: () => {
          this.$store.dispatch('clearActiveFlightTemplate');
          this.$emit('clear-data-from-template');
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
nav {
  padding: 30px;
  padding-top: 0;
  margin-left: -15px;
  height: 100%;
  background-color: @medium-tan;

  &.disabled {
    li {
      cursor: not-allowed;
    }
  }
}

h1 {
  font-size: 1.8rem;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}

ul {
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  border-bottom: solid 1px @tan;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: @medium-navy;
    padding: 1rem 1.5rem 1rem 0;
    cursor: pointer;

    svg {
      margin-right: 1.7rem;
    }

    &.selected {
      background: @teal;
      margin-left: -1rem;
      margin-right: -1rem;

      span {
        color: @navy;
        margin-left: 3.7rem;
      }

      svg {
        margin-left: 1rem;
      }
    }

    &.completed {
      color: @navy;

      span {
        margin-left: 0;
      }
    }

    &.unsaved {
      &::after {
        content: "*"
      }
    }

    span {
      margin-left: 2.7rem;
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 900;
      letter-spacing: 1.5px;
    }
  }
}

.marker {
  border-radius: 1px;
  background: @orange;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-left: 2rem;
}

.new-flight-progress-checkmark {
  width: 18px;
  height: 18px;
}

.flight-template {
  margin-bottom: 10rem;

  p { font-weight: 500; }

  button {
    text-transform: none;
    border: 1px solid @navy;
    padding: .8rem 1.5rem;
  }
}

.hover-tip {
  right: 0;
  margin-right: 2.3rem;

  div {
    position: absolute;
    left: 2.5rem;
    top: -10rem;
    min-height: 16rem;
    min-width: 25rem;
    transition: opacity .15s linear;

    p { color: @tan; }
  }

  svg { cursor: pointer; }

  &:hover div,
  &:focus div {
    opacity: 1 !important;
    visibility: visible;
    z-index: 1;
  }
}

@media (hover: hover) {
  .hover-tip:not(:hover) {
    div {
      opacity: 0;
      visibility: hidden;
    }
  }
}
</style>
