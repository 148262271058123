import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'preFlightList',
      'liveList',
      'completedList',
      'archivedList',
      'hasCreatorPerms',
      'loggedInUser',
      'currentStage',
    ]),

    hasParticipatedInFlight() {
      const stage = this.currentStage(this.flight.id);
      const { participated } = this.flight.users;

      if (!participated) {
        return false;
      }

      const participants = [];
      Object.keys(participated).forEach((q) => {
        if (stage === 'one') {
          participants.push(participated[q].stageOne);
        }
        participants.push(participated[q].stageOne);
        participants.push(participated[q].stageTwo);
      });

      return participants.flat().includes(this.loggedInUser.id);
    },
  },
};
