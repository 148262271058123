<template>
  <header class="row" v-if="activeQuestion">
    <div class="col pl-0">
      <div class="row">
        <div class="col">
          <h5>
            Question
            {{allQuestions.findIndex(q =>activeQuestion.id === q.id)+1}} of {{allQuestions.length}}
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col-9">
          <div class="question-list">
            <button
              :class="{ active: q.id === activeQuestion.id }"
              v-for="(q, idx) in allQuestions"
              :key="q.id"
              :data-tour="`question-select-${idx+1}`"
              @click="selectQuestion(q)">
              {{idx+1}}
            </button>
          </div>
        </div>

        <div v-if="isPostFlight" class="col-3">
          <ToggleSwitch v-model="presentationMode" data-tour="presentation-mode">
            Presentation Mode
          </ToggleSwitch>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col">
          <h3>{{ activeQuestion.text }}</h3>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import flightDetail from '@/mixins/flight-detail';

export default {
  name: 'LiveFlightQuestionHeader',
  mixins: [flightDetail],

  props: {
    flight: {
      type: Object,
      default: () => ({}),
    },

    value: { type: Boolean },
  },

  data() {
    return {
      presentationMode: false,
    };
  },

  computed: {
    ...mapGetters([
      'activeQuestion',
      'allQuestions',
      'balloonsForQ',
      'isMyBalloon',
    ]),

    balloonCount() {
      return this.balloonsForQ(this.activeQuestion.id).length;
    },

    myBalloonCount() {
      return this.balloonsForQ(this.activeQuestion.id)
        .filter((balloon) => this.isMyBalloon(balloon.id)).length;
    },
  },

  watch: {
    presentationMode(newVal) {
      this.$emit('input', newVal || false);
    },
  },

  methods: {
    ...mapActions([
      'setActiveQuestion',
    ]),

    selectQuestion({ id }) {
      if (this.$route.params.q === id) {
        return;
      }

      this.$router.replace({ name: 'flight-detail', params: { q: id, s: this.stage } });
      this.setActiveQuestion(id);
    },

    incrementQuestion(value) {
      const questions = this.allQuestions;
      let idx = questions.findIndex((q) => q.id === this.activeQuestion.id);
      idx = (idx + questions.length + value) % questions.length;
      this.setActiveQuestion(questions[idx].id);
    },
  },
};
</script>

<style lang="less" scoped>
header {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

h5 {
  font-size: 1rem;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: @green;
  margin-bottom: 2.4rem;
}

.question-list {
  display: flex;

  button {
    display: inline-block;
    margin-right: 1.2rem;
    width: 30px;
    height: 30px;
    color: @green;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    border: 1px solid @green;
    border-radius: 1px;
    cursor: pointer;

    transition: background-color 0.125s;

    &.active {
      background-color: @green;
      color: @white;
    }
  }
}
</style>
