<template>
  <div class="row bg-green h-100 flex-column align-items-start">
    <div id="template-top" />
    <div class="sidebar-image"
      :style="{background: `url(${imageSrc}) center / cover no-repeat,
                            url('/images/balloon-placeholder.svg') center / 50% no-repeat`}"
    />
    <div class="sidebar-info">
      <template v-if="authors.length === 1">
        <h1>{{authors[0].fullName}}</h1>
        <h4 class="mt-3">{{authors[0].title}}</h4>
        <h4 class="mt-5 bio" v-html="authors[0].bio"></h4>

        <div class="d-flex justify-content-end mt-4">
          <!-- <a href="#" class="mr-auto">Read More</a> -->
          <a v-for="l in authors[0].socialMediaProfiles"
            :key="l.platform"
            class="social-media ml-3"
            @click="redirectToAuthorPlatform(l.link)">
            <icon :name="`${l.platform}-logo`" height="17" width="20"/>
          </a>
        </div>
      </template>
      <template v-else>
        <h2 v-for="a in authors" :key="a.id" class="mt-3">{{a.fullName}}</h2>
      </template>
    </div>

    <div class="col mb-5">
      <hr class="my-5" />
      <div v-if="quote.length > 1" class="quote">
        <icon name="quotes" height="13" width="18" class="mb-4"/>
        <q class="mt-2">{{quote}}</q>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FlightTemplateSidebar',

  props: {
    imageSrc: { type: String, required: true },
    authors: { type: Array, required: true },
    quote: { type: String, required: false },
  },

  methods: {
    redirectToAuthorPlatform(link) {
      window.location = `${link}`;
    },
  },
};
</script>

<style lang="less" scoped>
.sidebar-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 330px;
  width: 100%;
}

.sidebar-info {
  padding: 4rem;
  > * { color: @tan !important }

  .bio { font-weight: 500 }

  ::v-deep a {
    color: inherit;
    font-size: inherit;
    border-bottom: solid 1px;
  }
}

hr { background-color: @pink; }

.quote {
  padding: 2.5rem;

  ::v-deep svg {
    path {
      fill: @pink;
    }
  }
}

q {
  color: @pink;
  font-size: 1.8rem;
  font-style: italic;
  font-weight: 500;
  line-height: 3rem;
  display: block;
}

a {
  color: @teal;
  font-weight: 900;
  border-bottom: solid 1px @teal;

  &.social-media {
    border: none;

    ::v-deep svg {
      path {
        fill: @teal;
      }
    }
  }
}

.light {
  background-color: @medium-tan !important;

  .sidebar-info > * { color: @navy !important; }

  hr { background-color: inherit; }

  .quote {
    background-color: @tan;
    padding: 3rem;

    ::v-deep svg {
    path {
      fill: @navy;
    }
  }
  }

  q { color: @navy !important; }

  a {
    color: @green;
    border-color: @green;

    & ::v-deep svg {
      path {
        fill: @green;
      }
    }
  }
}
</style>
