<template>
  <section class="row" aria-live="polite" aria-relevant="additions" aria-atomic="false">
    <div class="col">
      <transition-group tag="div" name="list">
        <Balloon
          v-for="balloon in balloons"
          :key="balloon.id"
          :balloon="balloon"
          :comment-mode="commentMode"/>
      </transition-group>
    </div>
  </section>
</template>

<script>
import Balloon from '@/components/Balloon/Balloon.vue';

export default {
  name: 'LiveFlightBalloonList',
  components: {
    Balloon,
  },

  props: {
    balloons: { type: Array, required: true },
    commentMode: { type: Boolean, default: false },
  },
};
</script>

<style lang="less" scoped>
.balloon-list {
  padding-bottom: 10rem;
}
</style>
