<template>
  <div class="time-input-group">
    <icon name="time" width="26" height="26"/>
    <b-input
      type="number"
      inputmode="numeric"
      v-model="hour"
      lazy-formatter
      placeholder="hh"
      maxlength="2"
      min="1" max="12"
      pattern="(^0?[1-9]$)|(^1[0-2]$)"
      :state="hourState"
      @blur.native="saveSection"/>

    <span class="field-text mx-2">:</span>

    <b-input
      type="number"
      inputmode="numeric"
      v-model="min"
      lazy-formatter
      placeholder="mm"
      maxlength="2"
      min="0"
      max="59"
      pattern="[0-9]{2}"
      :state="minState"
      @blur.native="saveSection"/>

    <b-dropdown no-caret>
      <template slot="button-content">
        <div class="d-flex align-items-center">
          <span class="field-text">{{ampm}}</span>
        </div>
      </template>
      <b-dropdown-item-button @click="setAmPm('am')">
        <span>am</span>
      </b-dropdown-item-button>
      <b-dropdown-item-button @click="setAmPm('pm')">
        <span>pm</span>
      </b-dropdown-item-button>
    </b-dropdown>

    <span class="timezone">{{timezone}}</span>
  </div>
</template>

<script>
export default {
  name: 'TimeInput',
  props: ['savedHour', 'savedMin'],
  data() {
    return {
      hour: this.parse24hourToString(this.savedHour) || 0,
      min: this.parseMinToString(this.savedMin) || 0,
      ampm: this.savedHour < 12 ? 'am' : 'pm',
      timezone: new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2],
    };
  },

  computed: {
    returnHour() {
      // should return Number between 0 and 23
      let returnVal = Number(this.hour);
      // if hour is 12, make it 0
      if (returnVal === 12) {
        returnVal = 0;
      }
      // if it is PM, add 12 hours
      if (this.ampm === 'pm') {
        returnVal += 12;
      }
      return returnVal;
    },

    returnMin() {
      // Should return Number between 0 and 59
      return Number(this.min);
    },

    hourState() {
      return /(^0?[1-9]$)|(^1[0-2]$)/.test(this.hour) && Number(this.hour) >= 1 && Number(this.hour) <= 12;
    },

    minState() {
      return /^[0-9]{2}/.test(this.min) && Number(this.min) >= 0 && Number(this.min) <= 59;
    },
  },

  watch: {
    savedHour() {
      this.hour = this.parse24hourToString(this.savedHour);
      this.ampm = this.savedHour < 12 ? 'am' : 'pm';
    },

    savedMin() {
      this.min = this.parseMinToString(this.savedMin);
    },
  },

  created() {
    this.saveSection();
  },

  methods: {
    parse24hourToString(val) {
      // * does not make in bounds! just changes to string of correct format *
      let parsedHour = Number(val);
      if (parsedHour < 0 || parsedHour > 23) {
        return String(parsedHour);
      }
      parsedHour %= 12;
      // increment by 12, if hour is 0
      if (parsedHour === 0) {
        parsedHour += 12;
      }
      return String(parsedHour);
    },

    parseMinToString(val) {
      // * does not make in bounds! just changes to string of correct format *
      const parsedMin = Number(val);
      if (parsedMin >= 0 && parsedMin < 10) {
        return `0${parsedMin}`;
      }
      return String(parsedMin);
    },

    setAmPm(val) {
      this.ampm = val;
      this.saveSection();
    },

    saveSection() {
      // allow input validation to set, then ensure both are validated before emitting
      this.$nextTick(() => {
        if (this.hourState && this.minState) {
          this.$emit('update-time', {
            hour: this.returnHour,
            min: this.returnMin,
          });
        } else {
          this.$emit('set-invalid');
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.time-input-group {
  padding: .7rem .9rem;
  align-items: center;
  border: solid 1px @light-navy;
  display: flex;
  min-width: 190px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance:textfield;
  }

  .dropdown {
    margin-left: 1rem;
    width: 30px;
  }

  svg {
    margin-right: .7rem;
    ::v-deep path {
      fill: none !important;
    }
  }

  input {
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1.38;
    padding: .2rem;
    text-align: center;
    width: 25px;
  }

  .field-text {
    text-transform: lowercase;
  }

  .timezone {
    font-family: Avenir;
    font-size: 1rem;
    font-weight: 900;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }
}
</style>
