<template>
  <section class="row" :class="{ 'full-screen': !showSidebar }">
    <div class="col">
      <form @submit.prevent="submitBalloon">
        <ExpandingTextarea
          v-model="balloonData.balloon.text"
          @keydown.meta.enter.native="submitBalloon"
          data-tour="balloon-answer"
          placeholder="Type your answer"/>
        <PrimaryButton
          type="submit"
          :primary="false"
          :pending="pending"
          :disabled="disable">
            Submit
          </PrimaryButton>
      </form>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'LiveFlightBalloonForm',

  props: {
    showSidebar: { type: Boolean, default: false },
  },

  data() {
    return {
      pending: true,
      balloonData: {},
    };
  },

  computed: {
    ...mapState({
      flightId: (state) => state.flights.activeFlightId,
      qId: (state) => state.flights.activeQuestionId,
    }),

    disable() {
      return this.pending || this.balloonData.balloon.text.length < 2;
    },
  },

  created() {
    this.resetData();
  },

  methods: {
    async submitBalloon() {
      if (this.disable) return;
      try {
        this.pending = true;
        this.balloonData.balloon.questionId = this.qId;
        this.balloonData.flightId = this.flightId;
        await this.$store.dispatch('createBalloon', this.balloonData);
        this.resetData();
      } catch (err) {
        this.pending = false;
      }
    },

    resetData() {
      this.pending = false;
      this.balloonData = {
        flightId: '',
        balloon: {
          questionId: '',
          text: '',
        },
      };
    },
  },
};
</script>

<style lang="less" scoped>
.row {
  position: fixed;
  bottom: 4rem;
  left: 32.5%;
  width: 60%;

  &.full-screen {
    width: 82%;
    left: 7%;
  }
}

form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -1.7%;
  padding: .5rem 3rem;
  border-radius: 1px;
  border: 1px solid @navy;
  background: @white;
  z-index: 1;
}

::v-deep textarea {
  &:focus {
    border: none;
  }

  min-height: 30px;
  height: 30px;

  font-size: 1.3rem;
  font-weight: 500;
  color: @navy;

  &::placeholder {
    font-size: 1.3rem;
    font-weight: 500;
    color: @navy;
  }
}

button {
  color: @green;
}
</style>
