import { format } from 'date-fns';

export default {
  methods: {
    formatDate(...args) {
      const timezone = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
      const date = format(...args);
      return `${date} ${timezone}`;
    },
  },
};
