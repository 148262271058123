<template>
  <article class="row" @click="goToFlight(flight.id)">
    <div class="col-10">
      <div class="row">
        <div class="col d-flex">
          <span v-if="!hasParticipatedInFlight" class="info-label">New Flight</span>
          <span v-if="isQuickFlight(flight.id)" class="info-label">Quick Flight Starting Soon</span>
          <span v-if="isEndingFlight(flight.id)" class="info-label">Ending Soon</span>
          <span v-if="isMyFlight(flight.id)" class="info-label">My Flight</span>
          <span v-else class="info-label">
            {{ flight.author.show ? userNameById(flight.createdUserId) : 'Anonymous' }}
          </span>
          <span v-if="flight.hasPrivateResults" class="info-label">
            <icon name="eye_closed"/>
            Private Results
          </span>
        </div>
      </div>

      <div class="row mt-2 d-flex align-items-center">
        <div class="col-5 ellipsis">
          <h4>{{ flight.name }}</h4>
        </div>
        <div class="col-4">
          <CopyToClipboard v-if="flight.shortcode" :content="shortlinkURL" />
        </div>
        <div class="col-3 text-right">
          <span v-if="isPreFlight(flight.id)" class="info-label">
            Starts in {{ timeLeftInStageString(flight.id) }}
          </span>
          <span v-else-if="currentStage(flight.id) === 'postFlight'" class="info-label">
            Ended {{ timeSinceFlightEnded(flight.id) }}
          </span>
          <span v-else class="info-label">
            {{ timeLeftInStageString(flight.id) }} left in stage {{ stageNumber(flight.id) }}
          </span>
        </div>
      </div>

    </div>
  </article>
</template>

<script>
import { mapGetters } from 'vuex';

import flightList from '@/mixins/flight-list';

import CopyToClipboard from '@/components/CopyToClipboard.vue';

export default {
  name: 'FlightListItem',
  mixins: [flightList],

  components: { CopyToClipboard },

  props: {
    flight: { type: Object, required: true },
  },

  computed: {
    ...mapGetters([
      'currentStage',
      'timeLeftInStageString',
      'timeSinceFlightEnded',
      'isQuickFlight',
      'isEndingFlight',
      'isMyFlight',
      'userNameById',
    ]),
    shortlinkURL() {
      if (this.flight.shortcode) {
        return `${process.env.VUE_APP_SHORTCODE_URL}${this.flight.shortcode}`;
      }
      return '';
    },
  },
  methods: {
    isPreFlight(flightId) {
      return this.currentStage(flightId) === 'preFlight';
    },

    goToFlight(id) {
      const route = this.isPreFlight(id) && this.isMyFlight(id)
        ? { name: 'flight-edit', params: { id } }
        : { name: 'flight-landing', params: { id } };

      this.$router.push(route);
    },

    stageNumber(flightId) {
      return this.currentStage(flightId) === 'one' ? '1' : '2';
    },
  },
};
</script>

<style lang="less" scoped>
article {
  border-radius: 1px;
  cursor: pointer;
  margin-bottom: 1.1rem;

  .col-10 {
    padding: 1.8rem 2.4rem;
    background:@medium-tan;

    span {
      color: @green;
    }

    &:hover {
      background: @green;

      h4, span, ::v-deep input {
        color: @light-tan;
      }

      ::v-deep path {
        stroke: @light-tan !important;
      }

      ::v-deep button {
        color: @light-tan;
        border-color: @light-tan;
      }

      .text-right span {
        color: @pink;
      }
    }
  }

  .info-label {
    margin-right: 1.5rem;
  }
}

::v-deep path {
  fill: none;
}
</style>
