<template>
  <div>
    <div class="row">
      <div class="col pl-0 pt-5">
        <h1>{{ title }} Flights<small>{{ ids.length }}</small></h1>
      </div>
    </div>

    <FlightListItem
      v-for="id in ids"
      :key="id"
      :flight="flights[id]"
    />
  </div>
</template>

<script>
import FlightListItem from '@/components/FlightList/FlightListItem.vue';

export default {
  name: 'FlighList',

  components: { FlightListItem },

  props: {
    ids: { type: Array, required: true },
    flights: { type: Object, required: true },
    title: { type: String, required: true },
  },
};
</script>

<style lang="less" scoped>
  h1 {
    margin-bottom: 2.9rem;

    small {
      margin-left: 2.4rem;
      font-size: 2.2rem;
      font-weight: normal;
    }
  }
</style>
