<template>
  <div class="scroll-wrapper" v-bar="{ scrollThrottle: 30 }">
    <div class="scroll-content">
      <div class="container-fluid h-100">
        <div class="row h-100 tw-select-none">
          <div class="col-3 d-flex justify-content-center">
            <FlightTemplateSidebar
              v-if="!isFromBalloon"
              :authors="[templateAuthor]"
              :quote="activeTemplate.authorQuote"
              :image-src="templateAuthor.thumbnailUrl"
            />
            <div v-else class="balloon-sidebar">
              <img src="/images/balloon-team-thumb.png" alt="The Balloon Team" width="122" />
              <h5 class="navy mt-5 mb-2">Written by</h5>
              <h3>The Balloon Team</h3>
            </div>
          </div>

          <div class="col-7 mb-8" :class="{'offset-1': !isFromBalloon }">
            <div class="row mt-5 mb-3">
              <div class="col col-lg-3">
                <BackToFlightsLink target="templates" />
              </div>
            </div>
            <div class="row" v-if="templateSeries.id">
              <div class="col-9 mt-5">
                <h5 class="text-uppercase green">
                  Flight Template Series:
                  <router-link
                    :to="{
                      name: 'flight-template-series',
                      params: { slug: this.templateSeries.slug }
                    }"
                    class="underline"
                  >
                    {{templateSeries.name}}
                  </router-link>
                </h5>
              </div>
            </div>

            <div class="row">
              <div class="col mt-5 d-flex justify-content-between align-items-baseline ">
                <h1>{{activeTemplate.name}}</h1>
                <div>
                  <router-link
                    data-testid="useFlightTemplate"
                    :to="'/flights/new'"
                    :disabled="!isActiveSubscription"
                    tag="button"
                    class="primary bg-orange mb-4">
                      Use this flight template
                    </router-link>
                    <Tip v-if="!isActiveSubscription" :show-title="false">
                      Reactivate your subscription to <br/> use this template
                    </Tip>
                  </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-9">
                <h4 class="my-3">Categories</h4>
                <Label v-for="(c, index) in activeTemplate.categories"
                  :key="'category-' + index"
                  class="category mr-3 mb-3"
                  background="pink"
                  :icon="c"
                >
                  {{c}}
                </Label>

                <h4 class="my-3 mt-6">Description</h4>
                <p class="description" v-html="activeTemplate.description"></p>

                <h4 class="my-4 mt-6">Suggested Questions</h4>
                <FlightTemplateQuestion
                  ref="flightTemplateQuestion"
                  v-for="(q, index) in questions"
                  :key="'question-' + index"
                  :question="q"
                />
                <p v-if="!isActiveSubscription">
                  +
                  {{activeTemplate.questions.length > 1
                      ? activeTemplate.questions.length - 1 : ''}} more
                </p>

                <div v-if="activeTemplate.insights && activeTemplate.insights.length">
                  <h4 class="my-3 mt-6">Types of Insights</h4>
                  <div v-for="i in activeTemplate.insights"
                    :key="i"
                    class="my-3">
                    <p>{{i}}</p>
                  </div>
                </div>

                <FlightTemplateCompanies
                  v-if="activeTemplate.companies && activeTemplate.companies.length"
                  :companies="activeTemplate.companies"
                />
              </div>
            </div>

            <div class="row mb-5" v-if="templateSeries.id && otherTemplates">
              <div class="col">
                <hr class="my-5" />
                <h5 class="text-uppercase green mb-5">
                  Other Flight Templates in this Series from this author
                </h5>
                <div class="row">
                  <FlightTemplateSeries
                    :active-template-id="activeTemplate.id"
                    :templateSeries="templateSeries"
                    :authors="authorsBySeriesId(templateSeries.id)"
                    :templates="seriesTemplates"
                    size='md'
                    @click="goToFlightTemplate"
                    @keypress.enter="goToFlightTemplate"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import FlightTemplateSidebar from '@/components/FlightTemplate/FlightTemplateSidebar.vue';
import FlightTemplateQuestion from '@/components/FlightTemplate/FlightTemplateQuestion.vue';
import FlightTemplateCompanies from '@/components/FlightTemplate/FlightTemplateCompanies.vue';

export default {
  name: 'FlightTemplateDetailView',

  components: {
    FlightTemplateSidebar,
    FlightTemplateQuestion,
    FlightTemplateCompanies,
  },

  computed: {
    ...mapGetters({
      activeTemplate: 'activeFlightTemplate',
      templateAuthor: 'activeTemplateAuthor',
      templateSeries: 'activeTemplateSeries',
      templateIdBySlug: 'flightTemplateIdBySlug',
      templateById: 'flightTemplateById',
      authorsBySeriesId: 'templateAuthorsBySeriesId',
      isActiveSubscription: 'isActiveSubscription',
    }),

    templateId() { return this.templateIdBySlug(this.$route.params.slug); },

    isFromBalloon() { return this.templateAuthor?.fullName === 'The Balloon Team'; },

    questions() {
      return this.isActiveSubscription
        ? this.activeTemplate.questions
        : [this.activeTemplate.questions[0]];
    },

    seriesTemplates() {
      return [...this.templateSeries.templateIds.map((tId) => this.templateById(tId))];
    },

    otherTemplates() {
      return this.templateSeries.templateIds
        .filter((tId) => this.templateById(tId).templateAuthorId === this.templateAuthor.id)
        .length > 1;
    },
  },

  created() {
    if (!this.templateId) {
      this.$store.dispatch('errorToast', 'Flight Template not found.');
      this.$router.push('/flights/templates');
    }

    this.setActiveFlightTemplate(this.templateId);
  },

  beforeRouteUpdate(to, from, next) {
    // Always scroll to the top of the page
    document.getElementById('template-top').scrollIntoView();
    next();
  },

  methods: {
    ...mapActions(['setActiveFlightTemplate']),

    goToFlightTemplate(id) {
      this.$router.push({ name: 'flight-template-detail', params: { slug: this.templateById(id).slug } });
      this.setActiveFlightTemplate(id);
    },
  },
};
</script>

<style lang="less" scoped>
h5 > a {
  font-size: inherit;
  color: inherit;
}

h1 + div button {
  width: max-content;
}

.balloon-sidebar {
  margin-top: 10rem;

  h5 { text-transform: uppercase; }
}

.description ::v-deep a { border-bottom: solid 1px @navy; }

.category {
  ::v-deep svg {
    path {
      fill: none;
      stroke: @navy;
    }
  }
}
</style>
