<template>
  <div v-if="content">
    <b-input
      v-if="content"
      ref="copyInput"
      data-testid="copyInput"
      type="text"
      :value="content"
      readonly>
    </b-input>
    <Button class="primary outline mr-5" @click.native.prevent.stop="copyToClipBoard">
      Copy
    </Button>
  </div>
</template>

<script>
import * as clipboardUtil from '@/utils/clipboard';

export default {
  name: 'CopyToClipboard',

  props: {
    content: { type: String, required: true },
    name: { type: String, default: 'Shortlink' },
  },

  methods: {
    copyToClipBoard() {
      const successful = clipboardUtil.copyTextFromInput(document, this.$refs.copyInput);
      if (successful) {
        this.$store.dispatch('successToast', `Copied ${this.name}!`);
        return;
      }

      this.$store.dispatch('errorToast', `Error copying ${this.name}!`);
    },
  },
};
</script>

<style lang="less" scoped>
div {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 300px;

  ::v-deep button.primary {
    margin: 0;
    margin-left: 2rem;
    padding: .4rem 1.2rem;
    border-radius: 1px;
  }

  input {
    margin: 0;
    color: @green;
    font-weight: 500;
    text-align: center;
    font-size: 1.2rem;
    background: transparent;
    border: none;
    padding: 0;
    max-width: 200px;

    &:focus {
      border-bottom: none;
    }
  }
}
</style>
