const refreshIntervals = {
  BALLOON_INTERVAL: 15000,
  FLIGHT_INTERVAL: 60000,
  flight: null,
  balloon: null,

  clear() {
    clearInterval(this.flight);
    clearInterval(this.balloon);
  },

  set(flighCb, balloonCb) {
    this.flight = setInterval(() => {
      try {
        flighCb();
      } catch (_) { } // eslint-disable-line no-empty
    }, this.FLIGHT_INTERVAL);
    this.balloon = setInterval(() => {
      try {
        balloonCb();
      } catch (_) { } // eslint-disable-line no-empty
    }, this.BALLOON_INTERVAL);
  },
};

export default refreshIntervals;
