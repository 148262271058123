<template>
  <section class="row">
    <div class="col">
      <h5 class="mb-4">Flight Template Used</h5>
      <FlightTemplateCard
        :template="template"
        :author="authorById(template.templateAuthorId)"
        size="sm"
        class="mt-4"
      />
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FlightSidebarFlightTemplate',

  computed: {
    ...mapGetters({
      templateById: 'flightTemplateById',
      authorById: 'templateAuthorById',
    }),

    template() { return this.templateById(this.templateId); },
  },

  props: {
    templateId: { type: String, required: true },
  },
};
</script>
