<template>
  <transition name="fade">
    <div class="dialog d-flex flex-column align-items-center">
      <h3 v-if="stage === 'one'">
        This flight has started. You can now create balloons!
      </h3>
      <h3 v-if="stage === 'two'">
        This flight has entered Stage 2. You can now pump up the Balloons you support!
      </h3>
      <template v-if="stage === 'postFlight'">
        <h2>Well done!</h2>
        <h3>This flight has ended. You can now view the results!</h3>
      </template>
      <button class="primary mt-6" @click="close">
        let's go
      </button>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'LiveFlightStateModal',

  props: {
    stage: { type: String, required: true },
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="less" scoped>

.dialog {
  background-color: @medium-tan;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1000;
  transform: translate(-50%, -50%);
  margin: 0;
  border: none;

  &::before {
    background-color: fade(@light-tan, 90%);
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100vh;
    width: 100vw;
    transform: translate(-50%, -50%);
    z-index: -1;
  }

  button {
    width: 270px;
  }
}
</style>
