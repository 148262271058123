<template>
  <section class="row" v-if="shortlinkURL">
    <div class="col">
      <h5 class="mb-4">Shortlink</h5>
      <CopyToClipboard :content="shortlinkURL" />
    </div>
  </section>
</template>

<script>
import CopyToClipboard from '@/components/CopyToClipboard.vue';

export default {
  name: 'FlightSidebarShortcode',
  components: { CopyToClipboard },

  props: {
    flight: { type: Object, required: true },
  },

  computed: {
    shortlinkURL() {
      if (this.flight.shortcode) {
        return `${process.env.VUE_APP_SHORTCODE_URL}${this.flight.shortcode}`;
      }
      return '';
    },
  },
};
</script>
