<template>
  <div class="d-flex flex-column w-100">
      <LoadingBalloonAnimation v-if="isFlightLoading" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import flighData from '@/mixins/flight-data';
import { allowedForFlight } from '@/utils/userToken';

export default {
  name: 'FlightLayout',
  mixins: [flighData],

  computed: {
    ...mapState({
      activeQuestionId: (state) => state.flights.activeQuestionId,
      questionsList: (state) => state.flights.questionsList,
    }),

    ...mapGetters([
      'currentStage',
      'isCredentialed',
    ]),
  },

  async created() {
    this.redirectToFlight();
  },

  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
    redirectToFlight() {
      const { id } = this.$route.params;
      if (!id) { return; }
      if (!allowedForFlight(id)) {
        this.logout();
      }
      this.$router.replace({ name: 'flight-detail', params: { id, s: this.currentStage(id) } });
    },
  },
};
</script>

<style lang="less">
.single-flight-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  max-width: 100%;

  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
}
</style>
