<template>
  <div class="row">
    <form class="col" @submit.prevent="saveSection">
      <h2>Set the timing for your flight.</h2>
      <p class="mt-5">Optionally, use one of our common flight duration presets:</p>
      <div class="row">
        <div class="col-8 mt-1 d-flex flex-wrap">
          <SelectionControl
            v-for="(duration, index) in defaultDurations"
            :key="duration.minutes"
            :data-testid="duration.minutes + 'Minutes'"
            :data-tour="`flight-duration-selector-${index+1}`"
            :is-active="isDurationActive(duration)"
            :is-invalid="flightMinutesDifference < 0"
            @select="setDuration(duration.minutes)">
            <span>{{ duration.label }}</span>
          </SelectionControl>
          <SelectionControl
            class="disabled custom"
            :is-active="isDurationActive()">
            <span>
              Custom
              <br>
              <small>
                {{ distanceInWords(stages.one.startDate, stages.two.endDate) }}
              </small>
            </span>
          </SelectionControl>
        </div>
        <div class="col-3 d-flex align-items-end mb-3">
          <Tip>
            We suggest 30 minutes for a live flight and 7 days for an asynchronous flight.
          </Tip>
        </div>
      </div>

      <div class="row">
        <div class="col-8 stage">
          <h2>When do you want stage 1 to start?</h2>
          <div class="d-flex align-items-center mt-5">
            <DateTimeInput ref="stageOneInput" @input="updateStages"
              v-model="stages.one.startDate"/>
            <h6 v-if="isAfter(stages.one.startDate, stageSplitDate)
                || isEqual(setSeconds(stages.one.startDate, 0), setSeconds(stageSplitDate, 0))">
              Invalid
            </h6>
            <h6
              v-else-if="isBefore(stages.one.startDate, stageSplitDate)"
              data-tour="stage-one-duration"
            >
              {{ distanceInWords(stages.one.startDate, stageSplitDate) }}
            </h6>
          </div>
        </div>
        <div class="col-3 d-flex align-items-end">
          <Tip>
            In Stage One, teams anonymously respond to questions—contributing their ideas,
            feedback, and insights through “balloons”—and collaborate.
          </Tip>
        </div>
      </div>

      <div class="row">
        <div class="col-8 stage">
          <h2>When do you want stage 2 to start?</h2>
          <div class="d-flex align-items-center mt-5">
            <DateTimeInput ref="stageTwoInput" @input="updateStages"
              v-model="stageSplitDate"/>
            <h6 v-if="isAfter(stages.one.startDate, stageSplitDate)
                || isEqual(stages.one.startDate, stageSplitDate)">
              Invalid
            </h6>
            <h6
              v-else-if="isBefore(stageSplitDate, stages.two.endDate)"
              data-tour="stage-two-duration"
            >
              {{ distanceInWords(stageSplitDate, stages.two.endDate) }}
            </h6>
          </div>
        </div>
        <div class="col-3 d-flex align-items-end">
          <Tip>
            In Stage Two, participants evaluate balloons by pumping up the ones they support using a
            research-backed Approval Voting system that eliminates groupthink and creates an idea
            and information meritocracy.
          </Tip>
        </div>
      </div>

      <div class="row">
        <div class="col stage">
          <h2>When do you want the flight to end?</h2>
          <div class="d-flex align-items-center mt-5">
            <DateTimeInput ref="flightEndInput" @input="updateStages"
              v-model="stages.two.endDate"/>
            <h6 v-if="isAfter(stages.one.startDate, stages.two.endDate)
               || isAfter(stageSplitDate, stages.two.endDate)
               || isEqual(stageSplitDate, stages.two.endDate)">
              Invalid
            </h6>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-7 mt-6">
          <Button
            type="submit"
            class="lg primary"
            :disabled="!canSaveSection"
            data-testid="doneEditTiming"
            :is-loading="isLoading">
            {{ editMode ? 'Update' : 'Save & Continue' }}
          </Button>

          <Button
            type="reset"
            @click.native.prevent="cleanSection"
            class="lg tertiary mt-4">
            Clear
          </Button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {
  distanceInWords,
  differenceInMinutes,
  setSeconds,
  addMinutes,
  isBefore,
  isAfter,
  isEqual,
} from 'date-fns';
import DateTimeInput from '@/components/DateTimeInput.vue';
import SelectionControl from '@/components/SelectionControl.vue';

export default {
  name: 'FlightTimingSection',

  components: {
    SelectionControl,
    DateTimeInput,
  },

  props: {
    defaultStages: {
      type: Object,
      default: () => ({
        one: {
          startDate: Date(),
          endDate: Date(),
        },
        two: {
          startDate: Date(),
          endDate: Date(),
        },
      }),
    },

    defaultDurations: {
      type: Array,
      default: () => ([
        {
          minutes: 20,
          label: '20 Minutes',
        },
        {
          minutes: 30,
          label: '30 Minutes',
        },
        {
          minutes: 10080,
          label: '1 Week',
        },
      ]),
    },

    editMode: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      distanceInWords,
      differenceInMinutes,
      addMinutes,
      isBefore,
      setSeconds,
      isAfter,
      isEqual,
      stages: JSON.parse(JSON.stringify(this.defaultStages)),
    };
  },

  computed: {
    flightMinutesDifference() {
      const difference = differenceInMinutes(this.stages.two.endDate, this.stages.one.startDate);
      return difference;
    },

    stageSplitDate: {
      get() {
        return this.stages.one.endDate;
      },
      set(date) {
        this.stages.one.endDate = date;
        this.stages.two.startDate = date;
      },
    },

    areDatesValid() {
      return isBefore(this.stages.one.startDate, this.stages.two.endDate)
        && isBefore(this.stages.one.startDate, this.stageSplitDate)
        && isBefore(this.stageSplitDate, this.stages.two.endDate)
        && !isEqual(this.stageSplitDate, this.stages.two.endDate)
        && !isEqual(setSeconds(this.stages.one.startDate, 0), setSeconds(this.stageSplitDate, 0));
    },

    canSaveSection() {
      if (!this.areDatesValid) {
        return false;
      }

      if (this.editMode
        && this.defaultStages.one.startDate === this.stages.one.startDate
        && this.defaultStages.one.endDate === this.stages.one.endDate
        && this.defaultStages.two.endDate === this.stages.two.endDate) {
        return false;
      }

      return true;
    },
  },

  methods: {
    updateStages() {
      if (this.stages.one.startDate !== this.defaultStages.one.startDate
        || this.stages.one.endDate !== this.defaultStages.one.endDate
        || this.stages.two.endDate !== this.defaultStages.two.endDate) {
        this.$emit('dirty');
      }
    },

    isDurationActive(duration) {
      if (!duration) {
        return !this.defaultDurations
          .map(({ minutes }) => minutes)
          .includes(this.flightMinutesDifference);
      }

      if (this.flightMinutesDifference < 0 && !duration.minutes) {
        return true;
      }

      return duration.minutes
        ? duration.minutes === this.flightMinutesDifference
        : this.flightMinutesDifference > 60;
    },

    setDuration(minutes) {
      if (!minutes) {
        return;
      }

      const flightStart = this.stages.one.startDate;
      const stageSplit = addMinutes(flightStart, minutes * (2 / 3));
      const flightEnd = addMinutes(flightStart, minutes);

      this.stages = {
        one: {
          startDate: flightStart,
          endDate: stageSplit,
        },
        two: {
          startDate: stageSplit,
          endDate: flightEnd,
        },
      };

      this.updateStages();
    },

    cleanSection() {
      this.stages = JSON.parse(JSON.stringify(this.defaultStages));
      this.$emit('clean');
    },

    saveSection() {
      this.$emit('save', {
        key: 'timing',
        section: {
          stages: this.stages,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
h6 {
  color: @orange;
  margin-left: 2.7rem;
}
.stage {
  margin-top: 6rem;
}
@media (max-width: 1366px)
{
  .stage {
    margin-top: 10rem;
  }
}

.tab {
  max-height: 50px;

  &.custom {
    display: none;
    padding: 0;

    &.active {
      display: inline-block;
    }
  }

  small {
    font-size: .8rem;
  }
}

.tip {
  margin-top: 1rem;
}
</style>
