<template>
  <section class="col">
    <template v-if="!isSuccess">
      <div class="scroll-wrapper" v-bar="{ scrollThrottle: 30 }">

        <div class="scroll-content">
          <div class="container-fluid h-100">
            <div class="row h-100">
              <div class="col-3">
                <FlightFormSidebar
                  :flight-template="flightTemplate"
                  :flight-sections="flightSections"
                  :selected-flight-section="selectedFlightSection"
                  :disabled="isReviewing"
                  @select-flight-section="selectFlightSection"
                  @clear-data-from-template="clearDataFromTemplate"
                  @unlink-template="unlinkTemplate"/>
              </div>

              <div class="col offset-1 pb-5" v-if="!isReviewing">
                <FlightNameSection
                  v-if="selectedFlightSection === 'name'"
                  :default-name="flight.name"
                  :default-description="flight.description"
                  @clean="setSectionClean('name')"
                  @dirty="setSectionNotSaved('name')"
                  @save="updateSectionData"/>

                <FlightQuestionsSection
                  v-if="selectedFlightSection === 'questions'"
                  :default-questions="flight.questions"
                  @clean="setSectionClean('questions')"
                  @dirty="setSectionNotSaved('questions')"
                  @save="updateSectionData"/>

                <FlightParticipantsSection
                  v-if="selectedFlightSection === 'participants'"
                  :default-user-ids="flight.userIds"
                  :default-emails="flight.emails"
                  :default-domain-lock="flight.excludeExternalContributors"
                  :default-segment-ids="flight.segmentIds"
                  :default-new-segment-name="newSegmentName"
                  :default-save-segment="saveSegment"
                  :has-shortlink="flight.useShortlink"
                  @clean="setSectionClean('participants')"
                  @dirty="setSectionNotSaved('participants')"
                  @save="updateSectionData"
                  @create-segment="updateSaveSegment"/>

                <FlightTimingSection
                  v-if="selectedFlightSection === 'timing'"
                  :default-stages="flight.stages"
                  @clean="setSectionClean('timing')"
                  @dirty="setSectionNotSaved('timing')"
                  @save="updateSectionData"/>

                <FlightOptionsSection
                  v-if="selectedFlightSection === 'options'"
                  :default-options="{
                    showAuthor: flight.showAuthor,
                    hasPrivateResults: flight.hasPrivateResults
                  }"
                  @clean="setSectionClean('options')"
                  @dirty="setSectionNotSaved('options')"
                  @save="updateSectionData"/>
              </div>

              <div class="col-5 offset-1 pb-5" v-else>
                <FlightReview :flight="flight" :user-count="userCount"/>

                <div class="row mt-5">
                  <div class="col">
                     <Button
                      class="primary outline lg"
                      @click.native="toggleReview(false)">
                        back to edit
                    </Button>
                  </div>
                  <div class="col">
                    <Button
                      class="primary lg"
                      @click.native="createFlight"
                      data-testid="setFlightButton"
                      :disabled="!areSectionsValid"
                      :is-loading="isLoading">
                        set flight
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LeaveGuard />
      </div>
    </template>

    <template v-else>
      <FlightSuccess :start-date="stageOneStartDate" :shortlink="shortlinkURL" />
    </template>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

import flightForm from '@/mixins/flight-form';
import FlightReview from '@/components/FlightForm/FlightReview.vue';
import FlightSuccess from '@/components/FlightForm/FlightSuccess.vue';

export default {
  name: 'FlightNewView',
  mixins: [flightForm],

  components: {
    FlightSuccess,
    FlightReview,
  },

  data() {
    return {
      isReviewing: false,
      isSuccess: false,

      saveSegment: false,
      newSegmentName: '',
      returnedFlightShortcode: '',
    };
  },

  computed: {
    ...mapGetters({
      flightTemplate: 'activeFlightTemplate',
      loggedInUser: 'loggedInUser',
      uniqueUserCount: 'uniqueUserCount',
    }),

    stageOneStartDate() {
      return this.flight.stages.one.startDate;
    },

    stageTwoStartDate() {
      return this.flight.stages.two.startDate;
    },

    stageTwoEndDate() {
      return this.flight.stages.two.endDate;
    },

    userCount() {
      return this.uniqueUserCount(
        this.flight.userIds,
        this.flight.emails,
        this.flight.segmentIds,
      );
    },

    shortlinkURL() {
      if (this.returnedFlightShortcode) {
        return `${process.env.VUE_APP_SHORTCODE_URL}${this.returnedFlightShortcode}`;
      }
      return '';
    },
  },

  created() {
    this.flight.userIds = [this.loggedInUser.id];

    if (this.$route.query.template && !this.flightTemplate.id) {
      this.$store.dispatch('setActiveFlightTemplate', this.$route.query.template);
    }

    if (this.flightTemplate.id) {
      this.setDataFromTemplate();
    }
  },

  beforeRouteLeave(to, from, next) {
    if (to.path === this.$store.state.utils.leaveGuardTo || this.isSuccess) {
      this.$store.dispatch('clearActiveFlightTemplate');
      return next();
    }

    this.$store.dispatch('showLeaveGuard', {
      to: to.path,
      message: 'Do you really want to leave? Your changes will be discarded!',
      okText: 'Discard changes',
    });

    return next(false);
  },

  async $route(to, from) {
    if (to.name === 'flight-detail' && to.params.id !== from.params.id) {
      this.fetchData();
      return;
    }

    if (to.name === 'flight-detail' && to.params.qid !== this.activeQuestionId) {
      this.setActiveQuestion();
    }
  },

  methods: {
    setDataFromTemplate() {
      this.flight.name = this.flightTemplate.flightName;
      this.flight.description = this.flightTemplate.flightDescription;
      this.flight.questions = this.flightTemplate.questions.map((q) => ({ text: q }));
      this.flight.templateId = this.flightTemplate.id;
    },

    clearDataFromTemplate() {
      this.flight.name = '';
      this.flight.description = '';
      this.flight.questions = [{ text: '' }];
      this.flight.templateId = '';
      this.setSectionNotCompleted('name');
      this.setSectionNotCompleted('questions');
    },

    unlinkTemplate() { this.flight.templateId = ''; },

    async createFlight() {
      if (this.isLoading) { return; }

      try {
        this.isLoading = true;
        const {
          flight, stageOneStartDate, stageTwoStartDate, stageTwoEndDate,
        } = this;

        const returnedFlight = await this.$store.dispatch('createFlight', {
          ...flight, stageOneStartDate, stageTwoStartDate, stageTwoEndDate,
        });

        this.returnedFlightShortcode = returnedFlight.shortcode;

        if (this.saveSegment) {
          const newSegment = {
            criteria: null,
            name: this.newSegmentName,
            emails: [],
            userIds: [...returnedFlight.users.invited.userIds],
            segmentIds: [...returnedFlight.users.invited.segmentIds],
            isSaved: true,
          };
          await this.$store.dispatch('createSegment', newSegment);
          this.$store.dispatch('successToast', 'Audience saved as new segment!');
        }

        this.isSuccess = true;
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
section.col {
  padding-left: 0;
}

::v-deep form {
  padding-top: 7rem;
}
</style>
