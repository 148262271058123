<template>
  <div class="flights-list-view container-fluid h-100 d-flex flex-column" id="flight-list-view">
    <FlightListHeader v-model="selectedOptions" :options="options" :is-loading="isLoading"/>

    <div class="row flex-grow-1">
      <div class="scroll-wrapper" v-bar="{ scrollThrottle: 30 }">
        <div class="scroll-content">
          <div class="container-fluid">
            <div class="row">
              <div class="col pb-5">

                <LoadingBalloonAnimation v-if="isLoading" text="Loading flights..." />

                <div class="row" v-if="selectedOptions.length === 0 ">
                  <div class="col-10 offset-2">
                    <FlightListEmpty :show-button="hasCreatorPerms" />
                  </div>
                </div>

                <div v-for="option in options" :key="option.key">
                  <div class="row" v-if="selectedOptions.includes(option.key)">
                    <div class="col-10 offset-2">
                      <FlightList
                        v-if="$store.getters[option.key].length"
                        :ids="$store.getters[option.key]"
                        :flights="flights"
                        :title="option.title"
                      />
                      <FlightListEmpty
                        v-else
                        :title="option.title"
                        :text=" option.text"
                        :show-button="option.title === 'Live' && hasCreatorPerms"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import flightList from '@/mixins/flight-list';

import FlightList from '@/components/FlightList/FlightList.vue';
import FlightListEmpty from '@/components/FlightList/FlightListEmpty.vue';
import FlightListHeader from '@/components/FlightList/FlightListHeader.vue';

export default {
  name: 'FlightsListView',
  mixins: [flightList],

  components: {
    FlightList,
    FlightListEmpty,
    FlightListHeader,
  },

  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      options: [
        {
          title: 'Upcoming',
          key: 'preFlightList',
          text: 'You’ll get a notification when you’re invited to a flight. You can also start your own at any time.',
        },
        {
          title: 'Live',
          key: 'liveList',
          text: 'You’ll get a notification when you’re invited to a flight. You can also start your own at any time.',
        },
        {
          title: 'Recently Completed',
          key: 'completedList',
          text: 'Your flight will show up here when it is over. You will be able to see the results once the flight is completed.',
        },
        {
          title: 'Archived',
          key: 'archivedList',
          text: 'Your completed flights will show up here once they have been over for 2 or more weeks. You will still be able to see the results.',
        },
      ],
      selectedOptions: [],
    };
  },

  computed: {
    ...mapState({
      flights: (state) => state.flights.all,
    }),

    ...mapGetters([
      'isCredentialed',
    ]),
  },

  activated() {
    if (!this.isCredentialed) {
      if (Object.keys(this.flights).length > 0) {
        this.$router.push(`/flights/${Object.keys(this.flights)[0]}/q`);
      } else {
        this.$router.push('/flights/none');
      }
    }
  },
};
</script>
