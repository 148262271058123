<template>
  <div class="scroll-wrapper" v-bar="{ scrollThrottle: 30 }">
    <div class="scroll-content">
      <div class="container-fluid h-100">
        <div class="row h-100 tw-select-none">
          <div class="col-3">
            <FlightTemplateSidebar
              :authors="authorsBySeriesId(templateSeries.id)"
              :quote="templateSeries.authorQuote"
              :image-src="templateSeries.thumbnailUrl"
              class="light"
            />
          </div>

          <div class="col-7 offset-1 mb-8">
            <div class="flex-shrink-0 mt-5 mb-3">
              <BackToFlightsLink target="templates" />
            </div>
            <h5 class="mt-5 text-uppercase green">
              Flight Template Series
            </h5>
            <h1 class="my-5">{{templateSeries.name}}</h1>

            <h4 class="my-4">Description</h4>
            <p class="w-75" v-html="templateSeries.description"></p>

            <h4 class="mt-6">Flight Templates</h4>
            <div class="my-3" v-if="templateSeries.templateIds">
              <div class="row">
                <FlightTemplateSeries
                  :authors="authorsBySeriesId(templateSeries.id)"
                  :templates="templates"
                  :templateSeries="templateSeries"
                  size='md'
                  @click="goToFlightTemplate"
                  @keypress.enter="goToFlightTemplate"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FlightTemplateSidebar from '@/components/FlightTemplate/FlightTemplateSidebar.vue';

export default {
  name: 'FlightTemplatesSeriesView',

  components: {
    FlightTemplateSidebar,
  },

  data() {
    return {
      templateSeries: {},
    };
  },

  computed: {
    ...mapGetters({
      templateById: 'flightTemplateById',
      seriesById: 'templateSeriesById',
      seriesIdBySlug: 'templateSeriesIdBySlug',
      authorsBySeriesId: 'templateAuthorsBySeriesId',
    }),

    seriesId() { return this.seriesIdBySlug(this.$route.params.slug); },

    templates() {
      return [...this.templateSeries.templateIds.map((tId) => this.templateById(tId))];
    },
  },

  created() {
    this.$store.dispatch('clearActiveFlightTemplate');

    if (!this.seriesId) {
      this.$store.dispatch('errorToast', 'Flight Template Series not found.');
      this.$router.push('/flights/templates');
    } else {
      this.templateSeries = this.seriesById(this.seriesId);
    }
  },

  beforeRouteLeave(to, from, next) {
    // Always scroll to the top of the page
    document.getElementById('template-top').scrollIntoView();
    next();
  },

  methods: {
    ...mapActions(['clearActiveFlightTemplate']),

    goToFlightTemplate(id) {
      this.$router.push({ name: 'flight-template-detail', params: { slug: this.templateById(id).slug } });
    },
  },
};
</script>
