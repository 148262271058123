<template>
  <div class="scroll-wrapper" v-bar="{ scrollThrottle: 30 }">
    <div class="scroll-content">

      <div class="container-fluid">
        <div class="col">

          <div class="row mt-7 mb-4 flex-shrink-0" >
            <div class="col-10 offset-1 d-flex justify-content-between align-items-center">
              <h1>Flight Templates</h1>
            </div>
          </div>

          <div class="row mb-8">
            <FlightTemplatesFilter
              ref="templatesFilter"
              :categories="categories"
              :filterValues="filterValues"
              />
            <div class="col-10 offset-1 d-flex flex-column flex-grow-1">
              <div class="row">
                <div class="col">
                  <div class="row d-flex align-items-stretch">
                    <div class="col-12 col-xl-6"
                      v-for="template in filteredTemplates.featured"
                      :key="template.id"
                    >
                      <FlightTemplateFeaturedCard
                        :template="template"
                        @keydown.enter.native="goToFlightTemplate(template.id)"
                        @click.native="goToFlightTemplate(template.id)"
                      />
                    </div>
                  </div>
                  <div class="row d-flex align-items-stretch">
                    <div class="col-12 col-md-6 col-xl-4"
                      v-for="template in filteredTemplates.templates"
                      :key="template.id">
                      <FlightTemplateCard
                        ref="templateCard"
                        data-tour="flight-template"
                        :template="template"
                        :author="templateAuthorById(template.templateAuthorId)"
                        @keydown.enter.native="goToFlightTemplate(template.id)"
                        @click.native="goToFlightTemplate(template.id)"
                        class="mb-5"
                      />
                    </div>
                    <div
                      class="col-12"
                      v-if="!filteredTemplates.featured.length
                            && !filteredTemplates.templates.length"
                    >
                      <h4 class="tw-text-medium-navy">No Flight Templates match that search.</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FlightTemplateFeaturedCard from '@/components/FlightTemplateList/FlightTemplateFeaturedCard.vue';
import FlightTemplatesFilter from '@/components/FlightTemplateList/FlightTemplatesFilter.vue';

export default {
  name: 'FlightTemplatesView',

  components: {
    FlightTemplatesFilter,
    FlightTemplateFeaturedCard,
  },

  data() {
    return {
      filterValues: {
        searchString: '',
        selectedCategory: 'all',
      },
    };
  },

  computed: {
    ...mapGetters({
      flightTemplates: 'allFlightTemplates',
      activeTemplate: 'activeFlightTemplate',
      categories: 'flightTemplateCategories',
      authorsNames: 'allTemplateAuthorsNames',
      templateAuthorById: 'templateAuthorById',
    }),

    filteredTemplates() {
      let featured = this.flightTemplates.filter((template) => template.categories.includes('Featured'));
      let templates = this.flightTemplates.filter((template) => !template.categories.includes('Featured'));
      // first filter by selection
      if (this.filterValues.selectedCategory !== 'all') {
        featured = featured.filter((template) => template.categories
          .some((category) => this.filterValues.selectedCategory.includes(category)));

        templates = templates.filter((template) => !template.categories.includes('Featured') && template.categories
          .some((category) => this.filterValues.selectedCategory.includes(category)));
      }
      // further filter if search is in use
      if (this.filterValues.searchString.length > 0) {
        const query = this.filterValues.searchString.toLowerCase();
        // filter authors
        const filteredAuthors = Object.keys(this.authorsNames)
          .filter((authorId) => this.authorsNames[authorId].toLowerCase().includes(query));

        templates = templates.filter((template) => template.name.toLowerCase().includes(query)
                                        || template.description.toLowerCase().includes(query)
                                        || filteredAuthors.includes(template.templateAuthorId));

        featured = featured.filter((template) => template.name.toLowerCase().includes(query)
                                        || template.description.toLowerCase().includes(query)
                                        || filteredAuthors.includes(template.templateAuthorId));
      }
      return { templates, featured };
    },
  },

  created() {
    this.$store.dispatch('clearActiveFlightTemplate');
  },

  methods: {
    ...mapActions(['setActiveFlightTemplate', 'clearActiveFlightTemplate']),

    goToFlightTemplate(id) {
      this.setActiveFlightTemplate(id);
      document.getElementById('top-bar').scrollIntoView(); // Scroll to top of the page

      this.$router.push({ name: 'flight-template-detail', params: { slug: this.activeTemplate.slug } });
    },
  },
};
</script>
