<template>
  <section class="row">
    <form class="col-7">
      <div class="row">
        <div class="col">
          <h2>Who do you want to invite to the flight?</h2>

          <p v-if="isSamlAuth" class="mb-5">Domain Lock:
            <b>{{ domainLock ? 'ON' : 'OFF'}}</b>
            <ToggleSwitch
              v-model="domainLock"
              :disabled="!allowsExternalContribution"
              />
          </p>

          <SelectionControl
            v-for="audience in audienceTypes"
            :key="audience.value"
            :data-testid="`audience-${audience.value}`"
            v-show="showAudienceType(audience.value)"
            :is-active="selectedAudience === audience.value"
            @select="audienceTypeSelected(audience.value)">
            <span>{{ audience.label }}</span>
          </SelectionControl>

          <AudienceTypeahead
            v-if="selectedAudience === 'manual'"
            @audience-updated="updateAudience"
            @init="setResetAudience"
            @validate="setValidAudience"
            :allow-saved-segments="false"
            :allow-external-domains="isSamlAuth ? !domainLock : true"
            :emails-prop="emails"
            :user-ids-prop="userIds"
            :segment-ids-prop="segmentIds"
            :user-limit="participantLimitOnActiveTeam" />
        </div>
      </div>

      <div class="row">
        <div class="col mt-7" v-if="selectedAudience === 'manual'">
          <h2>Do you want to save this list as a new segment?</h2>
          <b-checkbox
            v-model="saveSegment"
            @change.native="setFocusOnName"
            id="name-segment-checkbox">
            Yes, save as a new segment
          </b-checkbox>
          <div
            v-show="saveSegment"
            class="input-group"
            :class="{ invalid: !isSegmentValid || !isSegmentUnique }">
            <b-input
              type="text"
              v-model.trim="newSegmentName"
              lazy-formatter
              class="bordered"
              id="new-segment-name-input"
              ref="new-segment-name-input"
              placeholder="New Segment Name"
              aria-label="New Segment Name"/>
            <div class="error">
              <icon name="remove" color="orange" width="7" height="7"/>
              <span v-show="!isSegmentValid">
                The segment name is required
              </span>
              <span v-show="!isSegmentUnique">
                You alread have a segment called "{{ newSegmentName }}"
              </span>
            </div>
          </div>
        </div>
        <div class="col invalid" v-else-if="!canEditSection">
          <div class="error">
            <icon name="remove" color="orange" width="7" height="7"/>
            <span>You cannot edit participants on a flight with a Shortlink!</span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col mt-6">
          <Button
            class="lg primary"
            @click.native="saveSection"
            :is-loading="isLoading"
            :disabled="!canSaveSection"
            data-testid="doneEditParticipants">
              {{ editMode ? 'Update' : 'Save & Continue' }}
          </Button>

          <Button
            type="reset"
            @click.native.prevent="cleanSection"
            class="lg tertiary mt-4">
            Clear
          </Button>
        </div>
      </div>

    </form>

    <aside class="col-3 offset-1 pt-5">
      <Tip v-if="isSamlAuth" :showTitle="false" class="mb-6">
        <template v-if="domainLock">
          Participants will be limited to users at the following domains:
          <strong v-for="(d, i) in allowedDomains" :key="'domain-' + i">
            {{i === allowedDomains.length - 1 ? d + '.' : d + ','}}
          </strong>
        </template>
        <template v-else>
          Participants can join this flight using <strong>any</strong> email address.
        </template>
      </Tip>

      <Tip>
        <template v-if="selectedAudience === 'manual'">
          Type or copy and paste email addresses (separated by commas) of the people you want to
        invite to this flight.
        </template>
        <template v-else>
          Drop a shortlink in a Zoom chat, a Slack channel, a Google hangout, or a Teams
          conversation to have participants quickly and easily join a flight.
        </template>
      </Tip>
    </aside>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

import { deepEquals } from '@balloonplatform/client-lib/lib/js/utils';
import SelectionControl from '@/components/SelectionControl.vue';
import AudienceTypeahead from '@/components/AudienceTypeahead.vue';

export default {
  name: 'FlightParticipantsSection',

  components: { AudienceTypeahead, SelectionControl },

  props: {
    defaultUserIds: { type: Array, default: () => [] },
    defaultEmails: { type: Array, default: () => [] },
    defaultSegmentIds: { type: Array, default: () => [] },
    defaultNewSegmentName: { type: String, default: '' },
    defaultSaveSegment: { type: Boolean, default: false },
    defaultDomainLock: { type: Boolean, default: true },
    hasShortlink: { type: Boolean, default: true },
    editMode: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      segmentIds: this.defaultSegmentIds,
      userIds: this.defaultUserIds,
      emails: this.defaultEmails,
      newSegmentName: this.defaultNewSegmentName,
      saveSegment: this.defaultSaveSegment,
      selectedAudience: this.hasShortlink ? 'link' : 'manual',
      audienceTypes: [
        { label: 'Use a link', value: 'link' },
        { label: 'Invite by Email', value: 'manual' },
      ],
      isAudienceValid: false,
      domainLock: this.defaultDomainLock,
    };
  },

  computed: {
    ...mapGetters(['mySavedSegments',
      'uniqueUserCount',
      'loggedInUser',
      'activeTeam',
      'participantLimitOnActiveTeam',
      'isSamlAuth',
      'allowsExternalContribution',
      'allowedDomains',
    ]),

    canEditSection() {
      if (this.hasShortlink) {
        return !this.editMode;
      }

      return true;
    },

    userCount() {
      return this.uniqueUserCount(this.userIds, this.emails, this.segmentIds);
    },

    isSegmentUnique() {
      const lowerName = this.newSegmentName.toLowerCase();
      return !this.mySavedSegments.some((seg) => seg.name.toLowerCase() === lowerName);
    },

    isSegmentValid() {
      return this.newSegmentName;
    },

    canSaveSection() {
      if (!this.isSectionValid) {
        return false;
      }

      if (this.editMode) {
        return !deepEquals(this.segmentIds, this.defaultSegmentIds)
         || !deepEquals(this.emails, this.defaultEmails)
         || !deepEquals(this.userIds, this.defaultUserIds)
         || this.defaultDomainLock !== this.domainLock;
      }

      return true;
    },

    isSectionValid() {
      return (this.selectedAudience === 'link')
        || (this.userCount > 1 && this.isAudienceValid
            && (this.saveSegment ? (this.isSegmentValid && this.isSegmentUnique) : true) && this.selectedAudience === 'manual');
    },
  },

  watch: {
    saveSegment(newSaveSegment, oldSaveSegment) {
      if (newSaveSegment !== oldSaveSegment && newSaveSegment !== this.defaultSaveSegment) {
        this.$emit('dirty');
        return;
      }

      if (newSaveSegment === this.defaultSaveSegment) {
        this.$emit('clean');
      }
    },

    newSegmentName(newSegmentName, oldSegmentName) {
      if (newSegmentName !== oldSegmentName && newSegmentName !== this.defaultNewSegmentName) {
        this.$emit('dirty');
        return;
      }

      if (newSegmentName === this.defaultNewSegmentName) {
        this.$emit('clean');
      }
    },
  },

  methods: {
    showAudienceType(audience) {
      if (!this.editMode) {
        return true;
      }

      return audience === 'link' ? this.hasShortlink : !this.hasShortlink;
    },

    audienceTypeSelected(audience) {
      this.selectedAudience = audience;
      if (audience === 'link') {
        this.setAudience();
      }
    },

    updateAudience({ segmentIds, userIds, emails }) {
      if (!deepEquals(this.defaultSegmentIds, segmentIds)
        || !deepEquals(this.defaultUserIds, userIds)
        || !deepEquals(this.defaultEmails, emails)
        || this.defaultDomainLock !== this.domainLock) {
        this.$emit('dirty');
      } else {
        this.$emit('clean');
      }

      this.segmentIds = segmentIds;
      this.userIds = userIds;
      this.emails = emails;
    },

    saveSection() {
      const payload = {
        key: 'participants',
        section: {
          segmentIds: [],
          userIds: [this.loggedInUser.id],
          emails: [],
          useShortlink: true,
          excludeExternalContributors: this.isSamlAuth
            ? this.domainLock
            : false,
        },
      };

      if (this.selectedAudience === 'manual') {
        payload.section = {
          segmentIds: this.segmentIds,
          userIds: this.userIds,
          emails: this.emails,
          useShortlink: false,
          excludeExternalContributors: this.isSamlAuth
            ? this.domainLock
            : false,
        };

        this.$emit('create-segment', {
          saveSegment: this.saveSegment,
          newSegmentName: this.newSegmentName,
        });
      }

      this.$emit('save', payload);
    },

    setFocusOnName() {
      this.$nextTick(() => {
        if (this.saveSegment) {
          this.$refs['new-segment-name-input'].focus();
        }
      });
    },

    setAudience() {
      this.segmentIds = [...this.defaultSegmentIds];
      this.userIds = [...this.defaultUserIds];
      this.emails = [...this.defaultEmails];
    },

    cleanSection() {
      this.$emit('clean');
      this.setAudience();
      this.newSegmentName = this.defaultNewSegmentName;
      this.saveSegment = this.defaultSaveSegment;
      this.domainLock = this.defaultDomainLock;

      if (this.$resetAudience) {
        this.$resetAudience();
      }
    },

    setResetAudience(fn) {
      this.$resetAudience = fn;
    },

    setValidAudience(val) {
      this.isAudienceValid = val;
    },
  },
};
</script>

<style lang="less" scoped>
h2 {
  margin-bottom: 3rem !important;
}

aside > .tip {
  margin-top: 10rem;
}

.input-group {
  margin-top: -20px;
}

::v-deep .custom-checkbox {
  margin-top: 1.6rem;

  label {
    padding-left: 2em;
    font-size: 1.2rem;
    line-height: 1.5;
  }
}
</style>
