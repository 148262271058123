<template>
  <div class="col-10 offset-1">
    <div class="row">
      <div class="col-3">
        <div class="input-group input-search">
          <b-input type="search"
            v-model.trim="filterValues.searchString"
            :state="searchState"
            lazy-formatter
            data-tour="search-template"
            id="flight-template-search"
            class="bordered iconed"/>
          <label for="flight-template-search">
            <icon name="search" />
            <span>Search</span>
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="category-group mt-5 mb-4 d-inline-flex flex-wrap justify-content-start">
        <b-radio
          :checked="!filterValues.selectedCategory"
          value="all"
          v-model="filterValues.selectedCategory"
          class="category"
          button>
          All
        </b-radio>
        <b-radio v-for="(category, index) in categories"
          :key="category"
          :id="'category-'+index"
          :value="category"
          v-model="filterValues.selectedCategory"
          class="category"
          button>
          {{category}}
        </b-radio>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FlightTemplatesFilter',

  props: {
    categories: { type: Array, required: true },
    filterValues: { type: Object, required: true },
  },

  watch: {
    filterValues() {
      this.$emit('input', this.filterValues);
    },
  },

  computed: {
    searchState() {
      return this.filterValues.searchString.length === 0 ? null : true;
    },
  },
};
</script>

<style lang="less" scoped>
#flight-template-search {
  -webkit-appearance: none;

  // Hide 'x' icon when input has focus in Safari, Chrome and IE
  &::-webkit-search-cancel-button { -webkit-appearance: none }
  &::-ms-clear, &::-ms-reveal {
    display: none;
    width : 0;
    height: 0;
  }
}

.category-group {
  margin: 0 -1.5rem;
}

.category {
  margin: 3rem;
  margin-top: 0;

  ::v-deep label.btn {
    font-size: 1.6rem;
    padding: 0.3rem 0;
    transition-duration: 0s;

    &.active {
      border-bottom: solid 2px @teal;
      font-weight: 900;
    }
  }
}
</style>
