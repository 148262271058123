<template>
  <div class="container-fluid">
    <section class="row">
      <div class="col">
        <BackToFlightsLink />
      </div>
    </section>

    <section class="row">
      <div class="col-12 d-flex flex-column justify-content-center align-items-center mt-7">
        <icon name="floating-balloon" height="124" width="157" />
        <h1 class="mb-3 mt-6" data-testid="successMessage">Congratulations!</h1>
        <h4>Your Flight will start on
          {{ formatDate(startDate, '  dddd, MMMM D, YYYY [at] h[:]mma') }}.</h4>
      </div>
    </section>

    <section class="row" v-if="shortlink">
      <div class="offset-3 col-6 shortlink">
        <p>
          Share this link with anyone you want to participate in this flight!
        </p>

        <CopyToClipboard class="mt-4" :content="shortlink" />
      </div>
    </section>
  </div>
</template>

<script>
import dateFns from '@/mixins/date-fns';
import CopyToClipboard from '@/components/CopyToClipboard.vue';

export default {
  name: 'FlightSuccess',
  mixins: [dateFns],

  components: { CopyToClipboard },

  props: {
    startDate: { type: [String, Date], required: true },
    shortlink: { type: String, required: false },
  },

  methods: {
    isPreFlight(flightId) {
      return this.currentStage(flightId) === 'preFlight';
    },
  },
};
</script>

<style lang="less" scoped>
  .success-message {
    padding-top: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      font-size: 1.6rem;
      font-weight: 900;
      line-height: 1.5;
    }
  }

  .shortlink {
    margin-top: 3rem;

    p {
      font-size: 1.4rem;
      line-height: 1.4;
      text-align: center;
    }
  }
</style>
