<template>
  <div class="date-input">

    <!-- Date Input Group -->
    <div class="date-input-button datepicker-trigger">
      <label :for="`dateinput_${this._uid}`">
        <icon name="calendar" width="26" height="26"/>
      </label>

      <button type="button" :id="`dateinput_${this._uid}`" @click="toggleCalendar">
        {{ value ? format(value, 'ddd. MMM. D, YYYY') : 'Select a date' }}
      <icon name="dropdown_open" width="10" height="10" :class="{ 'svg-down': isCalendarOpen }"/>
      </button>

      <AirbnbStyleDatepicker
        :mode="'single'"
        :months-to-show="1"
                :trigger-element-id="`dateinput_${this._uid}`"
        :trigger="isCalendarOpen"
        :fullscreen-mobile="true"
        :offset-y="8"
        :showShortcutsMenuTrigger="false"
        :min-date="minDateString"
        :date-one="dateString"
        @closed="toggleCalendar(false)"
        @date-one-selected="updateDate"/>
    </div>

    <TimeInput
      :savedHour="hours"
      :savedMin="minutes"
      @update-time="updateTime"/>

  </div>
</template>

<script>
import {
  format,
  getHours, getMinutes,
  setHours, setMinutes,
  getYear, getDayOfYear,
  setYear, setDayOfYear,
} from 'date-fns';
import TimeInput from '@/components/TimeInput.vue';

export default {
  name: 'DateTimeInput',

  components: {
    TimeInput,
  },

  props: {
    minDate: {
      type: [Date, String],
      default: () => new Date(),
    },
    value: {
      type: [Date, String],
      default: () => new Date(),
    },
  },

  data() {
    return {
      format,
      isCalendarOpen: false,
      timezone: new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2],
    };
  },

  computed: {
    hours() {
      return getHours(this.value);
    },
    minutes() {
      return getMinutes(this.value);
    },
    minDateString() {
      return format(this.minDate, 'YYYY-MM-DD');
    },
    dateString() {
      return format(this.value, 'YYYY-MM-DD');
    },
  },

  methods: {
    updateTime(time) {
      let date = new Date(this.value);
      date = setHours(date, time.hour);
      date = setMinutes(date, time.min);
      this.$emit('input', date.toISOString());
    },

    updateDate(dateString) {
      let date = new Date(this.value);
      date = setYear(date, getYear(dateString));
      date = setDayOfYear(date, getDayOfYear(dateString));
      this.$emit('input', date.toISOString());
    },

    toggleCalendar(show) {
      this.isCalendarOpen = typeof show === 'boolean' ? show : !this.isCalendarOpen;
    },
  },
};
</script>

<style lang="less" scoped>
.date-input {
  display: flex;
  justify-content: flex-start;
  height: 45px;
}

.date-input-button {
  align-items: center;
  border: solid 1px @light-navy;
  border-radius: 1px;
  color: @navy;
  display: flex;
  font-size: 1.3rem;
  font-weight: 500;;
  justify-content: flex-start;
  line-height: 1.38;
  min-width: 220px;
  padding: .7rem .9rem;
  text-transform: none;
  margin-right: 1.3rem;

  label {
    cursor: pointer;
  }

  button {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-left: 1.4rem;
    padding: 1rem 0;
    width: 100%;
  }
}
</style>
